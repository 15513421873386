import { Component, OnInit, AfterViewInit, ViewChildren, QueryList } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { AuthService } from '../services/auth.service';
import { PersonsService } from '../services/persons.service';
import { HorsesService } from '../services/horses.service';
import { EntriesService } from '../services/entries.service';
import { successNotification, errorNotification } from '../services/notifications.service';
import { SignaturePad } from 'angular-signature-pad-v2';
import { Router } from '@angular/router';
import { firstValueFrom } from 'rxjs';
import { ShowsService } from '../services/shows.service';

declare var $: any;

@Component({
  selector: 'app-create-entry',
  templateUrl: './create-entry.component.html',
  styleUrls: ['./create-entry.component.scss']
})

export class CreateEntryComponent implements OnInit, AfterViewInit {
  @ViewChildren(SignaturePad) signaturePads: QueryList<SignaturePad>;
  public horseForm: FormGroup;
  public ownerForm: FormGroup;
  public riderForm: FormGroup;
  public trainerForm: FormGroup;
  public payeeForm: FormGroup;
  public stablingForm: FormGroup;
  public taxId: string;
  public termsAndConditions: boolean;
  public discipline: any;
  public emergencyForm: FormGroup;
  public showInfo: any;
  public show: any;
  public personLogged: any;
  public personDocument: any;
  public entry: any;
  public conceptos: any[];
  public suggestions: any;
  public selects: any;
  public elements: any;
  public comision_stripe: number;
  public entryAmountWithCCFee: number;
  public signaturePadOptions: Object;
  public conceptosSeleccionados: Array<any>;
  public entriesDue: any;
  public entryAmount: number;
  public actions: any;
  public divisions: Array<any>;
  public loadingUsef: boolean;
  public loadingFei: boolean;
  public loadingEC: boolean;
  public organizer: string;
  public reining: boolean;
  private showID: string;
  public email: string;
  public uid: string;
  public personResponsible: string;
  public responsiblePersons: any[] = [];

  constructor(
    private _authService: AuthService,
    private _personsService: PersonsService,
    private _entriesService: EntriesService,
    private _horsesService: HorsesService,
    private _router: Router,
    private showsService: ShowsService
  ) {
    this.discipline = '';
    this.horseForm = new FormGroup({
      FEI: new FormControl('', [Validators.pattern('^[^\s]*$')]),
      USEF: new FormControl('', [Validators.pattern('^[^\s]*$')]),
      EC: new FormControl('', []),
      name: new FormControl('', [Validators.required])
    });
    this.ownerForm = new FormGroup({
      FEI: new FormControl('', []),
      USEF: new FormControl('', []),
      EC: new FormControl('', [
        Validators.pattern('^[0-9]*$'),
        Validators.maxLength(8),
        Validators.minLength(1)
      ]),
      firstname: new FormControl('', [Validators.required]),
      phonenumber: new FormControl('', [Validators.required]),
      lastname: new FormControl('', []),
      address: new FormControl('', [Validators.required]),
      address2: new FormControl('', []),
      city: new FormControl('', [Validators.required]),
      state: new FormControl('', [Validators.required]),
      zip: new FormControl('', [Validators.required]),
      country: new FormControl('', [Validators.required])
    });
    this.riderForm = new FormGroup({
      FEI: new FormControl('', []),
      USEF: new FormControl('', []),
      EC: new FormControl('', [
        Validators.pattern('^[0-9]*$'),
        Validators.maxLength(8),
        Validators.minLength(1)
      ]),
      firstname: new FormControl('', [Validators.required]),
      phonenumber: new FormControl('', [Validators.required]),
      lastname: new FormControl('', [Validators.required]),
      address: new FormControl('', [Validators.required]),
      address2: new FormControl('', []),
      city: new FormControl('', [Validators.required]),
      state: new FormControl('', [Validators.required]),
      zip: new FormControl('', [Validators.required]),
      country: new FormControl('', [Validators.required])
    });
    this.trainerForm = new FormGroup({
      USEF: new FormControl('', []),
      EC: new FormControl('', [
        Validators.pattern('^[0-9]*$'),  // Only numbers
        Validators.maxLength(8),         // Max 8 digits
        Validators.minLength(1)          // At least 1 digit
      ]),
      firstname: new FormControl('', [Validators.required]),
      lastname: new FormControl('', [Validators.required]),
      phonenumber: new FormControl('', [Validators.required]),
      address: new FormControl('', [Validators.required]),
      address2: new FormControl('', []),
      city: new FormControl('', [Validators.required]),
      state: new FormControl('', [Validators.required]),
      zip: new FormControl('', [Validators.required]),
      country: new FormControl('', [Validators.required])
    });
    this.stablingForm = new FormGroup({
      name: new FormControl('', [Validators.required]),
      lastname: new FormControl('', [Validators.required]),
    });
    this.emergencyForm = new FormGroup({
      fullname: new FormControl('', [Validators.required]),
      phone: new FormControl('', [Validators.required])
    });
    this.payeeForm = new FormGroup({
      USEF: new FormControl('', []),
      firstname: new FormControl('', [Validators.required]),
      phonenumber: new FormControl('', [Validators.required]),
      lastname: new FormControl('', []),
      address: new FormControl('', [Validators.required]),
      address2: new FormControl('', []),
      city: new FormControl('', [Validators.required]),
      state: new FormControl('', [Validators.required]),
      zip: new FormControl('', [Validators.required]),
      country: new FormControl('', [Validators.required])
    });
    this.entry = {
      classes: []
    };
    this.showInfo = {};
    this.suggestions = {
      horse: [],
      trainer: [],
      owner: [],
      rider: [],
      payee: []
    }
    this.divisions = [];
    this.actions = {
      modalForm: '',
    }
    this.conceptos = [];
    this.conceptosSeleccionados = [];
    this.personLogged = {};
    this.comision_stripe = 0;
    this.selects = {
      horse: '',
      trainer: '',
      owner: '',
      payee: '',
      rider: '',
      riders: []
    }
    this.termsAndConditions = false;
    this.taxId = '';
    this.signaturePadOptions = { // passed through to szimek/signature_pad constructor
      dotSize: 1,
      minWidth: 1,
      maxWidth: 1,
      backgroundColor: 'rgb(245, 245, 245)'
    };
    this.loadingUsef = false;
    this.loadingFei = false;
    this.showID = sessionStorage.getItem('showID')||'';
    this.email = localStorage.getItem('email')||(sessionStorage.getItem('email')||'');
    this.uid = localStorage.getItem('user_document_id')||(sessionStorage.getItem('user_document_id')||'');
    this.reining = sessionStorage.getItem('reining') === '1';
    this.personResponsible = '';
  }


  // TODO 1: Require entering at least one class for Traverse City Shows
  // TODO 2: Check inventory of additional charges selected by the user

  async ngOnInit(){
    const user = await this._authService.getLoggedUser();
    if(!this.email && user.email){
      this.email = user.email;
      localStorage.setItem('email', this.email);
    }
    if(!this.uid){
      this._personsService.getDocumentByEmail(this.email).then((doc: any) => {
        this.uid = doc.uid;
        localStorage.setItem('user_document_id', this.uid);
        console.log('User document id: ', this.uid);
      });

    }
    this.getCurrentShow()
    this.getShow();
  }

  ngAfterViewInit(): void {
    // Only subscribe to changes
    this.signaturePads.changes.subscribe(pads => {
      if (pads.length > 2) {
        this.resizeCanvas();
      }
    });
  }

  public addRider(riderId: string) {
    if (!riderId) return;
    
    // Check if rider is already selected
    if (this.selects.riders.includes(riderId)) {
      return;
    }

    // Add just the rider ID to selects.riders
    this.selects.riders.push(riderId);
    
    // If this is a newly added rider, refresh rider suggestions
    // This ensures we get the latest data from the database
    if (!this.suggestions.rider.some((r: any) => r.uid === riderId) && 
        !this.allPersons.some((p: any) => p.uid === riderId)) {
      this._personsService.getPersonsSuggestions(this.personDocument.uid || '', 'rider')
        .then((riders: any[]) => {
          // Update our suggestions with the fresh data
          this.suggestions.rider = riders;
          
          // Filter out the already selected riders
          this.suggestions.rider = this.suggestions.rider.filter((r: any) => 
            !this.selects.riders.includes(r.uid)
          );
        })
        .catch(err => {
          console.error('Error refreshing rider suggestions:', err);
        });
    } else {
      // Remove the selected rider from the suggestions dropdown
      this.suggestions.rider = this.suggestions.rider.filter((r: any) => r.uid !== riderId);
    }
    
    // Reset the dropdown
    this.selects.rider = '';

    // Log once after all operations are complete
    console.log('Rider added:', {
      riderId,
      currentRiders: this.selects.riders,
      riderDetails: this.getRiderDetails(riderId)
    });
  }

  public async getShow() {
    try {
      const response = await firstValueFrom(this.showsService.getShowById(this.showID));
      if (response.error) {
        errorNotification('Error', response.error);
        return;
      }
      const showData = response.data.concurso;
      this.show = showData;
      this.entriesDue = new Date(showData.entriesDue);
      this.entryAmount = showData.entryAmount;
      this.comision_stripe = showData.comision_stripe;
      this.entryAmountWithCCFee = this.entryAmount * (1 + this.comision_stripe);
      this.organizer = showData.organizer;
      this.reining = showData.reining === 1;
    } catch (reason: any) {
      errorNotification('Error', reason);
    }
  }

  public async getCurrentShow() {
    if (!this.showID) {
      this._router.navigate(['/select-show']);
      return;
    }
    const response: any = await this._entriesService.getEntryInfo(this.showID);
    if (response.error) {
      errorNotification(response.error.code, response.error.message);
      return;
    }
    this.entryAmount = response.entryAmount;
    //this.showInfo.entryAmount = response.entryAmount;
    this.showInfo.entriesDue = response.entriesDue;
    this.showInfo.cardPayment = response.cardPayment;
    this.showInfo.checkPayment = response.checkPayment;
    this.comision_stripe = response.comision_stripe;
    this.entryAmountWithCCFee = this.entryAmount * (1 + this.comision_stripe);
    this.showInfo.square_config = response.square_config || null;
    this.showInfo.showID = this.showID;
    this.organizer = response.id_club;
    this.getPersonLogged();
    this.getConceptos();

    return response;
  }

  public async getPersonLogged() {
    const response = await this._personsService.getByEmail(this.email, this.showInfo.showID);
    if (response.error) {
      //errorNotification(response.error.code, response.error.message);
      //return;
      console.log('Error getting person logged: ', response.message);
    }
    if (!response.error) this.personLogged = response.entrenador;

    this.personDocument = await this._personsService.getDocumentByEmail(this.email);

    this.getHorsesSuggestions();
    this.getPersonSuggestions('payee');
    this.getPersonSuggestions('owner');
    this.getPersonSuggestions('trainer');
    this.getPersonSuggestions('rider');
  }

  public async getHorsesSuggestions(select?: any) {
    if(!this.personDocument.uid||''){
      this.suggestions['horse'] = [];
      errorNotification('Not found', 'User document not found');
      return;
    }
    const suggestions = await this._horsesService.getHorsesSuggestions(this.personDocument.uid||'').catch(reason => ({ error: true, code: (reason.error||{}).message||'', message: (reason.error||{}).message||'' }));
    if (suggestions.error) {
      errorNotification(suggestions.code, `Error getting horses suggestions. ${suggestions.message}`);
      return;
    }
    this.suggestions['horse'] = suggestions||[];
    if (select) {
      this.selects.horse = select;
      $('#formModal').modal('hide');
    }
  }

  public async getPersonSuggestions(type: string, select?: any) {
    if(!this.personDocument.uid||''){
      this.suggestions[type] = [];
      errorNotification('Not found', 'User document not found');
      return;
    }
    const response: any = await this._personsService.getPersonsSuggestions(this.personDocument.uid||'', type).catch(e => e);
    if(response.error){
      this.suggestions[type] = [];
      errorNotification((response.error||{}).code||'', `Error getting person suggestions. ${(response.error||{}).message||''}`);
      return;
    }
    this.suggestions[type] = (response||[]).map((s: any) => ({ ...s, address: s.address || '', address2: s.address2 || '', city: s.city || '', state: s.state || '', zip: s.zip || '', country: s.country || '' }))||[];

    if(select) {
      this.selects[type] = select;
      $('#formModal').modal('hide');
    }
  }

  private setUsefLoader(loading: boolean) {
    this.loadingUsef = loading;
    if(loading){
      this.horseForm.disable();
      this.ownerForm.disable();
      this.riderForm.disable();
      this.trainerForm.disable();
      this.payeeForm.disable();
    } else{
      this.horseForm.enable();
      this.ownerForm.enable();
      this.riderForm.enable();
      this.trainerForm.enable();
      this.payeeForm.enable();
    }
  }

  private setFeiLoader(loading: boolean) {
    this.loadingFei = loading;
    if(loading){
      this.horseForm.disable();
      this.ownerForm.disable();
      this.riderForm.disable();
      this.trainerForm.disable();
      this.payeeForm.disable();
    } else{
      this.horseForm.enable();
      this.ownerForm.enable();
      this.riderForm.enable();
      this.trainerForm.enable();
      this.payeeForm.enable();
    }
  }

  private setNRHALoader(loading: boolean) {
    //this.loadingNRHA = loading;
    if(loading){
      this.horseForm.disable();
      this.ownerForm.disable();
      this.riderForm.disable();
      this.trainerForm.disable();
      this.payeeForm.disable();
    } else{
      this.horseForm.enable();
      this.ownerForm.enable();
      this.riderForm.enable();
      this.trainerForm.enable();
      this.payeeForm.enable();
    }
  }

  public getHorseByUSEF() {
    if (this.horseForm.value.USEF == '') return;
    this.setUsefLoader(true);
    this._horsesService.getHorseByUSEF(this.horseForm.value.USEF, this.showInfo.showID).subscribe(
      (response: any) => {
        if (!response.error) {
          this.horseForm.patchValue({
            FEI: response.usefData.fei || '',
            USEF: this.horseForm.value.USEF,
            EC: '',
            name: response.usefData.name || ''
          });
          this.entry.horseInfo = response.usefData;
        }
        this.setUsefLoader(false);
      },
      error => {
        this.setUsefLoader(false);
        console.warn(error)
      }
    )
  }

  public getHorseByFEI() {
    if (this.horseForm.value.FEI == '') return;
    this.setFeiLoader(true);
    this._horsesService.getHorseByFEI(this.horseForm.value.FEI).subscribe(
      (response: any) => {
        if(response.error){
          console.log('Error getting horse by FEI', response.error);
          this.setFeiLoader(false);
          return;
        }
        console.log('Horse by FEI', response.caballo);
        this.horseForm.patchValue({
          //FEI: this.horseForm.value.FEI,
          //USEF: response.caballo.usef || '',
          //EC: '',
          name: response.caballo.name || ''
        });
        this.entry.horseInfo = response.caballo;
        this.setFeiLoader(false);
      },
      error => {
        console.warn(error);
        this.setFeiLoader(false);
      }
    )
  }

  public getHorseByNRHA() {
    if (this.horseForm.value.NRHA == '') return;
    this.setNRHALoader(true);
    this._horsesService.getHorseByNrha(this.horseForm.value.NRHA).subscribe(
      (response: any) => {
        if(response.error){
          this.setNRHALoader(false);
          return;
        }
        this.horseForm.setValue({
          // FEI: this.horseForm.value.FEI,
          // USEF: this.horseForm.value.USEF,
          NRHA: this.horseForm.value.NRHA,
          name: response.caballo.name
        });
        this.entry.horseInfo = response.caballo;
        this.setNRHALoader(false);
      },
      error => {
        console.warn(error)
      }
    )
  }

  public async getPersonUsefData(usef: string, type: string) {
    if (!usef) return;
    this.setUsefLoader(true);
    try {
      const { error, message, usefData }: any = await firstValueFrom(this._personsService.getUsefData(usef));
      if (error) {
        errorNotification('Error', message);
        this.setUsefLoader(false);
        return;
      }
      const value: any = {
        USEF: usef,
        firstname: usefData.name || '',
        lastname: usefData.lastname || '',
        fei: usefData.fei || ''
      };

      if (type == 'payee') {
        //value['address'] = this.payeeForm.value.address || '';
        //value['address2'] = this.payeeForm.value.address2 || '';
        //value['city'] = this.payeeForm.value.city || '';
        //value['state'] = this.payeeForm.value.state || '';
        //value['zip'] = this.payeeForm.value.zip || '';
        //value['country'] = this.payeeForm.value.country || '';
        value['phonenumber'] = this.payeeForm.value.phonenumber || '';
        this.payeeForm.setValue(value);
        this.entry.payeeInfo = usefData;
      } else if (type == 'rider') {
        this.riderForm.patchValue(value);
        this.entry.riderInfo = value;
      } else if (type == 'owner') {
        this.ownerForm.patchValue(value);
        this.entry.ownerInfo = value;
      } else if (type == 'trainer') {
        this.trainerForm.patchValue(value);
        this.entry.trainerInfo = value;
      }
      this.setUsefLoader(false);
    } catch (reason: any) {
      errorNotification('Error', reason);
      this.setUsefLoader(false);
      return;
    }
  }

  public async getPersonECData(ec: string, type: string) {
    if (!ec || ec.length > 8 || !/^\d+$/.test(ec)) {
      errorNotification('Error', 'EC number must be between 1-8 digits');
      return;
    }
    
    this.setECLoader(true);
    
    try {
      const response = await firstValueFrom(this._personsService.getECData(ec));
      
      const ecData = response as { 
        name: string; 
        lastname: string; 
        dateOfBirth: string;
      };

      if (!ecData.name) {
        errorNotification('Error', 'EC number not found');
        return;
      }

      const value = {
        firstname: ecData.name || '',
        lastname: ecData.lastname || '',
      };

      switch (type) {
        case 'trainer':
          this.trainerForm.patchValue(value);
          this.entry.trainerInfo = value;
          break;
        case 'owner':
          this.ownerForm.patchValue(value);
          this.entry.ownerInfo = value;
          break;
        case 'rider':
          this.riderForm.patchValue(value);
          this.entry.riderInfo = value;
          break;
      }
      
    } catch (error: any) {
      console.error('Error getting EC Data:', error);
      errorNotification('Error', error.message || 'Failed to fetch EC data');
    } finally {
      this.setECLoader(false);
    }
  }

  public async getPersonByFEI() {
    if (this.riderForm.value.FEI == '') return;
    this.setFeiLoader(true);
    const response: any = await firstValueFrom(this._personsService.getPersonByFEI(this.riderForm.value.FEI));
    if (response.error){
      this.setFeiLoader(false);
      return
    }
    this.riderForm.get('firstname')?.setValue(this.riderForm.value.firstname||response.persona.name);
    this.riderForm.get('lastname')?.setValue(this.riderForm.value.lastname||response.persona.lastname);
    this.setFeiLoader(false);
  }

  public differentPayee(event: any) {
    this.actions.showPayeeForm = event.target.checked;
  }

  public async getConceptos() {
    const response: any = await this._entriesService.getConceptosNest(this.showInfo.showID);
    if (response.error) {
      errorNotification('Error', response.message);
      return;
    }
    this.conceptos = response.data;
  }

  public getDivisionsByType() {
    this.divisions = [];
    if (this.discipline == '' || this.discipline == 'Non_showing') return;
    const discipline = this.discipline == 'FEI_jumpers' ? 'jumpers' : this.discipline;
    console.log("Discipline: ", discipline);
    this._entriesService.getDivisionsByType(this.showInfo.showID, discipline).subscribe(
      response => {
        if(this.discipline == 'FEI_jumpers'){
          this.divisions = response.divisions.filter((division: any) => division.name.includes('FEI'));
          console.log('FEI Divisions: ', this.divisions);
        } else if (this.discipline == 'jumpers'){
          this.divisions = response.divisions.filter((division: any) => !division.name.includes('FEI'));
          console.log('Jumper Divisions: ', this.divisions);
        } else {
          this.divisions = response.divisions;
          console.log('Other Divisions: ', this.divisions);
        }
      },
      error => {
        console.warn(error)
      }
    )
  }

  /**
   * Add or update a charge to the selected concepts list
   */
  public agregarCargo(idConcepto: any, monto_unitario: any, nombre: any, event: any) {
    // Ensure conceptosSeleccionados is initialized
    if (!this.conceptosSeleccionados) {
      this.conceptosSeleccionados = [];
    }

    console.log("Conceptos seleccionados: ", this.conceptosSeleccionados);
    
    const qty = isNaN(event.target.valueAsNumber) ? 0 : event.target.valueAsNumber;
    const index = this.conceptosSeleccionados.findIndex(concepto => {
      return concepto.idConcepto == idConcepto;
    });
    
    if (qty == 0) { //Si es 0 borrar de los conceptos seleccionados
      if (index > -1) { //Ya existe en el array conceptos seleccionados
        this.conceptosSeleccionados.splice(index, 1);
      } else {
        //No existe entonces no hay que borrar nada
      }
    } else {
      if (index > -1) { //Ya existe en el array conceptos seleccionados hay que editarlo
        this.conceptosSeleccionados[index].qty = qty;
        this.conceptosSeleccionados[index].total = monto_unitario * qty;
      } else { //Aun no existe en el array hay que agregarlo
        this.conceptosSeleccionados.push({ idConcepto, monto_unitario, nombre, qty, total: monto_unitario * qty });
      }
    }
    
    // Force change detection
    this.conceptosSeleccionados = [...this.conceptosSeleccionados];
  }

  /**
   * Calculate the total amount for all selected additional items
   * @returns The total amount or 0 if no items are selected
   */
  public totalCargos(): number {
    if (!this.conceptosSeleccionados || this.conceptosSeleccionados.length === 0) {
      return 0;
    }
    return this.conceptosSeleccionados.reduce((a, b) => {
      return a + (b.total || 0);
    }, 0);
  }

  public addClassToEntry(ipc: any) {
    let index = this.entry.classes.findIndex((e: any) => e === ipc);
    (index == -1) ? this.entry.classes.push(ipc) : this.entry.classes.splice(index, 1);
  }

  public async save() {
    if (!this.validateForm()) {
      return;
    }

    const signatureTrainerSvg = this.getSignature('trainer');
    const signatureOwnerSvg = this.getSignature('owner');
    const signatureResponsibleSvg = this.getSignature('responsible');

    if (!signatureTrainerSvg || !signatureOwnerSvg || (this.show?.organizing_country === 'CAN' && !signatureResponsibleSvg)) {
      errorNotification('Error', 'Please sign all required signature fields');
      return;
    }

    const horse = this.suggestions.horse.find((e: any) => e.uid == this.selects.horse);
    const owner = this.getPersonDetails(this.selects.owner);
    const trainer = this.getPersonDetails(this.selects.trainer);
    const payee = this.getPersonDetails(this.selects.payee);
    const responsible = this.getPersonDetails(this.personResponsible);

    const document = {
      riderUSEF: horse?.USEF || '',
      riderFEI: horse?.FEI || '',
      riderFirstname: horse?.firstname || '',
      riderLastname: horse?.lastname || '',
      riderAddress: horse?.address || '',
      riderAddress2: horse?.address2 || '',
      riderPhonenumber: horse?.phonenumber || '',
      riderCity: horse?.city || '',
      riderState: horse?.state || '',
      riderZip: horse?.zip || '',
      riderCountry: horse?.country || '',
      riderEC: horse?.EC || '',
      signatureRider: signatureTrainerSvg || '',
      riders: this.selects.riders.map((riderId: any) => this.getRiderDetails(riderId)).map((r: any) => ({
        ...r,
        riderUSEF: r.USEF || '',
        riderFEI: r.FEI || '',
        riderFirstname: r.firstname || '',
        riderLastname: r.lastname || '',
        riderAddress: r.address || '',
        riderAddress2: r.address2 || '',
        riderPhonenumber: r.phonenumber || '',
        riderCity: r.city || '',
        riderState: r.state || '',
        riderZip: r.zip || '',
        riderCountry: r.country || '',
        signatureRider: r.signatureRider || ''
      })),
      payeeUSEF: payee?.USEF || owner?.USEF || '',
      payeeEC: payee?.EC || owner?.EC || '',
      payeename: payee?.firstname || owner?.firstname || '',
      payeeLastname: payee?.lastname || owner?.lastname || '',
      payeeAddress: payee?.address || owner?.address || '',
      payeeAddress2: payee?.address2 || owner?.address2 || '',
      payeePhonenumber: payee?.phonenumber || owner?.phonenumber || '',
      payeeCity: payee?.city || owner?.city || '',
      payeeState: payee?.state || owner?.state || '',
      payeeZip: payee?.zip || owner?.zip || '',
      payeeCountry: payee?.country || owner?.country || '',
      ownerUSEF: owner?.USEF || '',
      ownerFEI: owner?.FEI || '',
      ownerEC: owner?.EC || '',
      ownername: owner?.firstname || '',
      ownerLastname: owner?.lastname || '',
      ownerAddress: owner?.address || '',
      ownerAddress2: owner?.address2 || '',
      ownerPhonenumber: owner?.phonenumber || '',
      ownerCity: owner?.city || '',
      ownerState: owner?.state || '',
      ownerZip: owner?.zip || '',
      ownerCountry: owner?.country || '',
      trainerUSEF: trainer?.USEF || '',
      trainerEC: trainer?.EC || '',
      trainername: trainer?.firstname || '',
      trainerLastname: trainer?.lastname || '',
      trainerAddress: trainer?.address || '',
      trainerAddress2: trainer?.address2 || '',
      trainerPhonenumber: trainer?.phonenumber || '',
      trainerCity: trainer?.city || '',
      trainerState: trainer?.state || '',
      trainerZip: trainer?.zip || '',
      trainerCountry: trainer?.country || '',
      horseUSEF: horse?.USEF || '',
      horseFEI: horse?.FEI || '',
      horseEC: horse?.EC || '',
      horseName: horse?.name || '',
      emergencyContact: this.emergencyForm.value.fullname || '',
      emergencyPhone: this.emergencyForm.value.phone || '',
      stablingWith: `${this.stablingForm.value.name||''} ${this.stablingForm.value.lastname||''}`,
      discipline: this.discipline || '',
      conceptos: this.conceptosSeleccionados || '',
      taxID: this.taxId || '',
      user: this.personLogged || '',
      email: this.email,
      showID: this.showID,
      signatureTrainer: signatureTrainerSvg || '',
      signatureOwner: signatureOwnerSvg || '',
      signatureResponsible: signatureResponsibleSvg || '',
      status: 'draft',
      paymentReceived: false,
      termsAndConditions: this.termsAndConditions,
      pruebas: this.entry.classes || [],
      personResponsible: this.personResponsible || '',
      responsibleName: responsible?.firstname || '',
      responsibleLastname: responsible?.lastname || '',
      responsibleEC: responsible?.EC || '',
    }
  
    const response = await this._entriesService.saveEntryFirebase(document, this.uid).then(r => r, r => r);
    if (response.error) {
      errorNotification(response.error.code, response.error);
      return;
    }

    for(const pad of this.signaturePads.toArray()){
      pad.clear();
      pad.on();
    }
    this.horseForm.reset();
    this.ownerForm.reset();
    this.riderForm.reset();
    this.trainerForm.reset();
    this.stablingForm.reset();
    this.emergencyForm.reset();
    this.payeeForm.reset();

    this._router.navigate(['/']);
    successNotification('Registration saved', 'Your registration has been saved successfully');
  }

  public async saveSuggestions(e: any) {
    const address1 = document.querySelector('#address1') as HTMLInputElement;
    e.target.classList.add('was-validated');
    if (this.actions.modalForm == 'horse') {
      const horse = {
        USEF: this.horseForm.value.USEF || '',
        FEI: this.horseForm.value.FEI || '',
        EC: this.horseForm.value.EC || '',
        name: this.horseForm.value.name || '',
      };
      if (horse.USEF) {
        const horseSuggestion = this.suggestions['horse'].find((h: any) => horse.USEF == h.USEF);
        if (horseSuggestion) {
          this.getHorsesSuggestions(horseSuggestion.uid);
          return;
        }
      }

      if (!this.horseForm.valid) return;

      const { documentId, reason } = await this._horsesService.setHorseSuggestion(this.uid, horse).then(r => ({ documentId: r.documentId, reason: null })).catch(reason => ({ reason, documentId: null }));
      if (reason) {
        errorNotification(reason.error.code, reason.error.message);
        return;
      }
      this.getHorsesSuggestions(documentId);

    } else if (this.actions.modalForm == 'owner') {
      //this.ownerForm.get('address')?.setValue(address1.value);
      const owner = {
        USEF: this.ownerForm.value.USEF || '',
        FEI: this.ownerForm.value.FEI || '',
        firstname: this.ownerForm.value.firstname || '',
        lastname: this.ownerForm.value.lastname || '',
        address: this.ownerForm.value.address || '',
        address2: this.ownerForm.value.address2 || '',
        phonenumber: this.ownerForm.value.phonenumber || '',
        city: this.ownerForm.value.city || '',
        state: this.ownerForm.value.state || '',
        zip: this.ownerForm.value.zip || '',
        country: this.ownerForm.value.country || '',
        EC: this.ownerForm.value.EC || '',
      };
      if (owner.USEF) {
        const ownerSuggestion = this.suggestions['owner'].find((o: any) => owner.USEF == o.USEF);
        if (ownerSuggestion) {
          this.getPersonSuggestions('owner', ownerSuggestion.uid);
          return;
        }
      }

      if (!this.ownerForm.valid) return;

      const { documentId, reason } = await this._personsService.setPersonsSuggestion(this.uid, 'ownerSuggestions', owner).then(r => ({ documentId: r.documentId, reason: null })).catch(reason => ({ reason, documentId: null }));
      if (reason) {
        errorNotification(reason.error.code, reason.error.message);
        return;
      }
      this.getPersonSuggestions('owner', documentId);
    } else if (this.actions.modalForm == 'rider') {
      //this.riderForm.get('address')?.setValue(address1.value);
      const rider = {
        USEF: this.riderForm.value.USEF || '',
        FEI: this.riderForm.value.FEI || '',
        firstname: this.riderForm.value.firstname || '',
        lastname: this.riderForm.value.lastname || '',
        address: this.riderForm.value.address || '',
        address2: this.riderForm.value.address2 || '',
        phonenumber: this.riderForm.value.phonenumber || '',
        city: this.riderForm.value.city || '',
        state: this.riderForm.value.state || '',
        zip: this.riderForm.value.zip || '',
        country: this.riderForm.value.country || '',
        EC: this.riderForm.value.EC || '',
      };
      if (rider.USEF) {
        const riderSuggestion = this.suggestions['rider'].find((r: any) => rider.USEF == r.USEF);
        if (riderSuggestion) {
          this.getPersonSuggestions('rider', riderSuggestion.uid);
          return;
        }
      }

      if (!this.riderForm.valid) return;

      const { documentId, reason } = await this._personsService.setPersonsSuggestion(this.uid, 'riderSuggestions', rider).then(r => ({ documentId: r.documentId, reason: null })).catch(reason => ({ reason, documentId: null }));
      if (reason) {
        errorNotification(reason.error.code, reason.error.message);
        return;
      }
      await this.getPersonSuggestions('rider', documentId);
      this.addRider(documentId);
    } else if (this.actions.modalForm == 'trainer') {
      //this.trainerForm.get('address')?.setValue(address1.value);
      const trainer = {
        USEF: this.trainerForm.value.USEF || '',
        EC: this.trainerForm.value.EC || '',
        firstname: this.trainerForm.value.firstname || '',
        lastname: this.trainerForm.value.lastname || '',
        address: this.trainerForm.value.address || '',
        address2: this.trainerForm.value.address2 || '',
        phonenumber: this.trainerForm.value.phonenumber || '',
        city: this.trainerForm.value.city || '',
        state: this.trainerForm.value.state || '',
        zip: this.trainerForm.value.zip || '',
        country: this.trainerForm.value.country || ''
      };
      if (trainer.USEF) {
        const trainerSuggestion = this.suggestions['trainer'].find((t: any) => trainer.USEF == t.USEF);
        if (trainerSuggestion) {
          this.getPersonSuggestions('trainer', trainerSuggestion.uid);
          return;
        }
      }
      if (!this.trainerForm.valid) return;

      const { documentId, reason } = await this._personsService.setPersonsSuggestion(this.uid, 'trainerSuggestions', trainer).then(r => ({ documentId: r.documentId, reason: null })).catch(reason => ({ reason, documentId: null }));
      if (reason) {
        errorNotification(reason.error.code, reason.error.message);
        return;
      }
      this.getPersonSuggestions('trainer', documentId);
    } else if (this.actions.modalForm == 'payee') {
      //this.payeeForm.get('address')?.setValue(address1.value);
      const payee = {
        USEF: this.payeeForm.value.USEF || '',
        firstname: this.payeeForm.value.firstname || '',
        lastname: this.payeeForm.value.lastname || '',
        address: this.payeeForm.value.address || '',
        address2: this.payeeForm.value.address2 || '',
        phonenumber: this.payeeForm.value.phonenumber || '',
        city: this.payeeForm.value.city || '',
        state: this.payeeForm.value.state || '',
        zip: this.payeeForm.value.zip || '',
        country: this.payeeForm.value.country || '',
        EC: this.payeeForm.value.EC || '',
      };

      if (payee.USEF) {
        const payeeSuggestion = this.suggestions['payee'].find((t: any) => payee.USEF == t.USEF);
        if (payeeSuggestion) {
          this.getPersonSuggestions('payee', payeeSuggestion.uid);
          return;
        }
      }

      if (!this.payeeForm.valid) return;

      const { documentId, reason } = await this._personsService.setPersonsSuggestion(this.uid, 'payeeSuggestions', payee).then(r => ({ documentId: r.documentId, reason: null })).catch(reason => ({ reason, documentId: null }));
      if (reason) {
        errorNotification(reason.error.code, reason.error.message);
        return;
      }
      this.getPersonSuggestions('payee', documentId);
    } else {
      return;
    }
  }

  public newEntity(entity: string) {
    this.trainerForm.reset();
    this.riderForm.reset();
    this.ownerForm.reset();
    this.payeeForm.reset();
    this.horseForm.reset();
    this.actions.modalForm = entity;
    $('form').removeClass('was-validated');
    $('#formModal').modal('show');
  }

  /**
   * Esta funcion se ejecuta al cambiar el valor del checkbox de terms and conditions pero no hace nada como tal
   */
  TermsAndConditionsAggrement() {
    //Boolean this.termsAndConditions
  }

  /**
   * Funcion que se ejecuta al comezar a dibujar en el canvas de firma
   */
  drawStart(entity: string) {

  }

  /**
   * Funcion que se ejecuta al terminar de dibujar en el canvas de firma
   */
  drawComplete(entity: string) {

  }

  /**
   * Funcion que se ejecuta al limpiar el canvas de firma
   */
  clearDraw(entity: string, riderId: string = '') {
    if (entity == 'rider'){
      const signaturePadsRider = this.signaturePads.toArray().filter((sp: any) => sp['_el'].nativeElement.classList.contains('rider') && sp['_el'].nativeElement.id == riderId);
      for(const sinaturePad of signaturePadsRider){
        sinaturePad.clear();
      }
    }
    if (entity == 'trainer') {
      const signaturePadsTrainer = this.signaturePads.toArray().filter((sp: any) => sp['_el'].nativeElement.classList.contains('trainer'));
      for(const sinaturePad of signaturePadsTrainer){
        sinaturePad.clear();
      }
    }
    if (entity == 'owner'){
      const signaturePadsOwner = this.signaturePads.toArray().filter((sp: any) => sp['_el'].nativeElement.classList.contains('owner'));
      for(const sinaturePad of signaturePadsOwner){
        sinaturePad.clear();
      }
    }
  }

  showModal() { $('#agreementModal').modal('show'); }

  hideModal() { $('#agreementModal').modal('hide'); }

  resizeCanvas() {
    const pads = this.signaturePads?.toArray() || [];
    if (pads.length === 2) return;

    const offsetWidth = document.querySelector("signature-pad")?.parentElement?.offsetWidth||0;
    for(const sinaturePad of this.signaturePads.toArray() as any[]){
      sinaturePad.elementRef.nativeElement.querySelector("canvas").width = offsetWidth;
      sinaturePad.clear();
    } 
  }

  public getAddress(place: any, entity: string) {
    if (entity == 'rider') {
      if (!this.riderForm.value.address) this.riderForm.get('address')?.setValue(place.address);
      if (!this.riderForm.value.address2) this.riderForm.get('address2')?.setValue(place.address2);
      if (!this.riderForm.value.city) this.riderForm.get('city')?.setValue(place.city);
      if (!this.riderForm.value.state) this.riderForm.get('state')?.setValue(place.state);
      if (!this.riderForm.value.zip) this.riderForm.get('zip')?.setValue(place.zip);
      if (!this.riderForm.value.country) this.riderForm.get('country')?.setValue(place.country);
    } else if (entity == 'trainer') {
      if (!this.trainerForm.value.address) this.trainerForm.get('address')?.setValue(place.address);
      if (!this.trainerForm.value.address2) this.trainerForm.get('address2')?.setValue(place.address2);
      if (!this.trainerForm.value.city) this.trainerForm.get('city')?.setValue(place.city);
      if (!this.trainerForm.value.state) this.trainerForm.get('state')?.setValue(place.state);
      if (!this.trainerForm.value.zip) this.trainerForm.get('zip')?.setValue(place.zip);
      if (!this.trainerForm.value.country) this.trainerForm.get('country')?.setValue(place.country);
    } else if (entity == 'owner') {
      if (!this.ownerForm.value.address) this.ownerForm.get('address')?.setValue(place.address);
      if (!this.ownerForm.value.address2) this.ownerForm.get('address2')?.setValue(place.address2);
      if (!this.ownerForm.value.city) this.ownerForm.get('city')?.setValue(place.city);
      if (!this.ownerForm.value.state) this.ownerForm.get('state')?.setValue(place.state);
      if (!this.ownerForm.value.zip) this.ownerForm.get('zip')?.setValue(place.zip);
      if (!this.ownerForm.value.country) this.ownerForm.get('country')?.setValue(place.country);
    } else if (entity == 'payee') {
      if (!this.payeeForm.value.address) this.payeeForm.get('address')?.setValue(place.address);
      if (!this.payeeForm.value.address2) this.payeeForm.get('address2')?.setValue(place.address2);
      if (!this.payeeForm.value.city) this.payeeForm.get('city')?.setValue(place.city);
      if (!this.payeeForm.value.state) this.payeeForm.get('state')?.setValue(place.state);
      if (!this.payeeForm.value.zip) this.payeeForm.get('zip')?.setValue(place.zip);
      if (!this.payeeForm.value.country) this.payeeForm.get('country')?.setValue(place.country);
    }
  }

  public setECLoader(loading: boolean) {
    this.loadingEC = loading;
  }

  public async getHorseECData(ec: string) {
    if (!ec || ec.length > 8 || !/^\d+$/.test(ec)) {
      errorNotification('Error', 'EC number must be between 1-8 digits');
      return;
    }
    
    try {
      const response = await firstValueFrom(this._horsesService.getHorseECData(ec));
      
      const ecData = response as { 
        name: string;
        // Add other expected horse data fields here
      };

      if (!ecData.name) {
        errorNotification('Error', 'Horse EC number not found');
        return;
      }

      this.horseForm.patchValue({
        name: ecData.name
        // Add other fields as needed
      });
      
    } catch (error: any) {
      console.error('Error getting horse EC data:', error);
      errorNotification('Error', error.message || 'Failed to fetch horse EC data');
    }
  }

  get allPersons() {
    const allPersons = [
      ...this.suggestions.trainer,
      ...this.suggestions.rider, 
      ...this.suggestions.owner
    ];
    
    // Remove duplicates by USEF number, fallback to uid if no USEF
    return Array.from(
      new Map(
        allPersons.map(p => [p.USEF || p.uid, p])
      ).values()
    );
  }

  public getRiderDetails(riderId: string) {
    // First try to find in allPersons
    const rider = this.allPersons.find(person => person.uid === riderId);
    
    // If found, return it
    if (rider) {
      return rider;
    }
    
    // If not found in existing arrays, need to check if it was a newly added rider
    // This would be a newly added rider through the modal form
    // Let's manually get rider details again to ensure we have the latest data
    this._personsService.getPersonsSuggestions(this.personDocument.uid || '', 'rider')
      .then((riders: any[]) => {
        // Find the specific rider
        const newRider = riders.find(r => r.uid === riderId);
        if (newRider) {
          // Add to suggestions to make it available for future lookups
          if (!this.suggestions.rider.some((r: any) => r.uid === newRider.uid)) {
            this.suggestions.rider.push(newRider);
          }
        }
      })
      .catch(err => {
        console.error('Error fetching rider details:', err);
      });
    
    // Return a default object with basic info until the data is refreshed
    return {
      firstname: "Loading...",
      lastname: "",
      USEF: "",
      EC: ""
    };
  }

  public getPersonDetails(personId: string) {
    return this.allPersons.find(person => person.uid === personId);
  }

  private getSignature(entity: string) {
    const signaturePad = this.signaturePads.toArray().find((sp: any) => sp.elementRef.nativeElement.classList.contains(entity));
    if (signaturePad) {
      return atob((signaturePad.toDataURL('image/svg+xml')||'').replace('data:image/svg+xml;base64,', ''));
    }
    return null;
  }

  private getHorseDetails(horse: any) {
    return {
      USEF: horse.USEF || '',
      FEI: horse.FEI || '',
      EC: horse.EC || '',
      name: horse.name || '',
    };
  }

  private validateForm(): boolean {
    if (this.selects.trainer == '') {
      errorNotification('Error', 'Trainer has not been selected');
      return false;
    }
    if (this.selects.horse == '') {
      errorNotification('Error', 'horse has not been selected');
      return false;
    }
    if (this.selects.owner == '') {
      errorNotification('Error', 'Owner has not been selected');
      return false;
    }
    if (this.selects.riders.length == 0) {
      errorNotification('Error', 'Rider has not been selected');
      return false;
    }
    if (this.selects.payee == '' && this.actions.showPayeeForm) {
      errorNotification('Error', 'payee has not been selected');
      return false;
    }
    if (!this.emergencyForm.valid) {
      errorNotification('Error', 'Emergency form incomplete');
      return false;
    }
    if (this.discipline == '') {
      errorNotification('Error', 'Discipline field empty');
      return false;
    }
    if(!this.stablingForm.valid){
      errorNotification('Error', 'Stabling with form incomplete');
      return false;
    }
      
    // Check if class selection is required for this show (club_id === 565)
    if (this.show?.id_club === 562 && (!this.entry.classes || this.entry.classes.length === 0)) {
      errorNotification('Error', 'Please select at least one class for this entry');
      return false;
    }
      
    if (!this.termsAndConditions) {
      errorNotification('Error', 'Terms and Conditions checkbox not selected');
      return false;
    }
      
    //Validar que las firmas esten completas
    let signaturesValid = true
    for(const pad of this.signaturePads.toArray()){
      if(pad.isEmpty()) signaturesValid = false;
    }
    if (!signaturesValid) {
      errorNotification('Error', 'A signature is missing');
      return false;
    }

    return true;
  }

  public removeSpaces(event: any, field: string) {
    const input = event.target;
    const value = input.value.replace(/\s/g, '');
    input.value = value;
    this.horseForm.get(field)?.setValue(value);
  }
}
