<div class="wrapper">
    <app-top-menu></app-top-menu>
    <app-left-menu></app-left-menu>
    <div class="content-page">
        <div class="container-fluid">
            <div class="row mt-3">
                <h2>Catalog</h2>
                <div class="row">
                    <div class="col-6">
                        <div class="card">
                            <div class="card-header d-flex justify-content-between align-items-center">
                                <h4 class="header-title">Horses</h4>
                            </div>
                            <div class="card-body pt-0">
                                <div class="table-responsive mt-n2">
                                    <table class="table table-centered table-hover table-nowrap mb-0">
                                        <thead>
                                            <tr>
                                                <th scope="col">Name</th>
                                                <th scope="col">USEF</th>
                                                <th scope="col">FEI</th>
                                                <th scope="col" *ngIf="show?.organizing_country === 'CAN'">EC</th>
                                                <th scope="col">Action</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr *ngFor="let horse of horses">
                                                <td>
                                                    <div class="d-flex align-items-center">
                                                        <!-- <div class="flex-shrink-0">
                                                            <img class="rounded-circle"
                                                                src="assets/images/users/avatar-1.jpg" alt="Avtar image"
                                                                width="31">
                                                        </div> -->
                                                        <div class="flex-grow-1 ms-2">
                                                            {{horse.name}}
                                                        </div>
                                                    </div>
                                                </td>
                                                <td>{{horse.USEF}}</td>
                                                <td>{{horse.FEI}}</td>
                                                <td *ngIf="show?.organizing_country === 'CAN'">{{horse.EC}}</td>
                                                <td>
                                                    <a href="javascript:void(0);" 
                                                       (click)="editHorse(horse)"
                                                       class="font-18 text-info me-2" 
                                                       data-bs-toggle="tooltip"
                                                       data-bs-placement="top" 
                                                       aria-label="Edit"
                                                       data-bs-original-title="Edit">
                                                        <i class="uil uil-pen"></i>
                                                    </a>
                                                    <a href="javascript:void(0);" (click)="deleteHorse(horse.uid)"
                                                        class="font-18 text-danger" data-bs-toggle="tooltip"
                                                        data-bs-placement="top" aria-label="Delete"
                                                        data-bs-original-title="Delete"><i
                                                            class="uil uil-trash"></i></a>
                                                </td>
                                            </tr> <!-- end tr -->


                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="col-6">
                        <div class="card">
                            <div class="card-header d-flex justify-content-between align-items-center">
                                <h4 class="header-title">People</h4>
                            </div>
                            <div class="card-body pt-0">
                                <div class="table-responsive mt-n2">
                                    <table class="table table-centered table-hover table-nowrap mb-0">
                                        <thead>
                                            <tr>
                                                <th scope="col">Name</th>
                                                <th scope="col">USEF</th>
                                                <th scope="col">FEI</th>
                                                <th scope="col" *ngIf="show?.organizing_country === 'CAN'">EC</th>
                                                <th scope="col">Action</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr *ngFor="let person of allPersons">
                                                <td>
                                                    <div class="d-flex align-items-center">
                                                        <div class="flex-grow-1 ms-2">
                                                            {{person.firstname}} {{person.lastname}}
                                                        </div>
                                                    </div>
                                                </td>
                                                <td>{{person.USEF}}</td>
                                                <td>{{person.FEI}}</td>
                                                <td *ngIf="show?.organizing_country === 'CAN'">{{person.EC}}</td>
                                                <td>
                                                    <a href="javascript:void(0);" 
                                                       (click)="editPerson(person)"
                                                       class="font-18 text-info me-2" 
                                                       data-bs-toggle="tooltip"
                                                       data-bs-placement="top" 
                                                       aria-label="Edit"
                                                       data-bs-original-title="Edit">
                                                        <i class="uil uil-pen"></i>
                                                    </a>
                                                    <a href="javascript:void(0);" 
                                                       (click)="deletePerson(person.uid, getRoles(person))"
                                                       class="font-18 text-danger" 
                                                       data-bs-toggle="tooltip"
                                                       data-bs-placement="top" 
                                                       aria-label="Delete"
                                                       data-bs-original-title="Delete">
                                                        <i class="uil uil-trash"></i>
                                                    </a>
                                                </td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div class="row">
                    <div class="card">
                        <div class="card-body">
                            <i class="uil uil-comment-alt-lines font-18" data-bs-toggle="tooltip" data-bs-placement="top" aria-label="Chat" data-bs-original-title="Chat"></i>
                            This section is under active development, expect to see features like adding and editing horses and people soon.
                        </div>

                    </div>
                </div>
            </div>
        </div>
    </div>
</div>

<!-- Add this at the bottom of the template -->
<div class="modal fade" id="deleteModal" tabindex="-1" role="dialog" aria-labelledby="deleteModalLabel" aria-hidden="true">
  <div class="modal-dialog" role="document">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title" id="deleteModalLabel">Confirm Delete</h5>
        <button type="button" class="btn close" data-bs-dismiss="modal" aria-label="Close">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div class="modal-body">
        Are you sure you want to delete this {{itemToDelete?.type}}?
      </div>
      <div class="modal-footer">
        <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">Cancel</button>
        <button type="button" class="btn btn-danger" (click)="confirmDelete()">Delete</button>
      </div>
    </div>
  </div>
</div>

<!-- Add form modal -->
<div id="formModal" class="modal fade" tabindex="-1" role="dialog">
  <div class="modal-dialog">
    <div class="modal-content">
      <div class="modal-header">
        <h4 class="modal-title">Edit {{actions.modalForm}}</h4>
        <button type="button" class="btn close" data-bs-dismiss="modal">×</button>
      </div>
      <div class="modal-body">
        <!-- Horse form -->
        <form *ngIf="actions.modalForm === 'horse'" [formGroup]="horseForm" class="needs-validation">
          <div class="mb-3">
            <label class="form-label">Name <span class="text-danger">*</span></label>
            <input type="text" class="form-control" formControlName="name" required>
          </div>
          <div class="mb-3">
            <label class="form-label">USEF</label>
            <div class="input-group">
              <input type="text" class="form-control" formControlName="USEF" (blur)="getHorseUSEFData($any($event.target).value)">
              <span class="input-group-text" *ngIf="loadingUSEF"><i class="fas fa-spinner fa-spin"></i></span>
            </div>
          </div>
          <div class="mb-3">
            <label class="form-label">FEI</label>
            <div class="input-group">
              <input type="text" class="form-control" formControlName="FEI" (blur)="getHorseFEIData($any($event.target).value)">
              <span class="input-group-text" *ngIf="loadingFEI"><i class="fas fa-spinner fa-spin"></i></span>
            </div>
          </div>
          <div class="mb-3" *ngIf="show?.organizing_country === 'CAN'">
            <label class="form-label">EC</label>
            <div class="input-group">
              <input type="text" class="form-control" formControlName="EC" (blur)="getHorseECData($any($event.target).value)">
              <span class="input-group-text" *ngIf="loadingEC"><i class="fas fa-spinner fa-spin"></i></span>
            </div>
          </div>
        </form>

        <!-- Person form -->
        <form *ngIf="actions.modalForm === 'person'" [formGroup]="personForm" class="needs-validation">
          <div class="row">
            <div class="col-md-6 mb-3">
              <label class="form-label">First Name <span class="text-danger">*</span></label>
              <input type="text" class="form-control" formControlName="firstname" required>
            </div>
            <div class="col-md-6 mb-3">
              <label class="form-label">Last Name <span class="text-danger">*</span></label>
              <input type="text" class="form-control" formControlName="lastname" required>
            </div>
          </div>
          <div class="mb-3">
            <label class="form-label">Phone <span class="text-danger">*</span></label>
            <input type="text" class="form-control" formControlName="phonenumber" required>
          </div>
          <div class="mb-3">
            <label class="form-label">USEF</label>
            <div class="input-group">
              <input type="text" class="form-control" formControlName="USEF" (blur)="getPersonUSEFData($any($event.target).value)">
              <span class="input-group-text" *ngIf="loadingUSEF"><i class="fas fa-spinner fa-spin"></i></span>
            </div>
          </div>
          <div class="mb-3">
            <label class="form-label">FEI</label>
            <div class="input-group">
              <input type="text" class="form-control" formControlName="FEI" (blur)="getPersonFEIData($any($event.target).value)">
              <span class="input-group-text" *ngIf="loadingFEI"><i class="fas fa-spinner fa-spin"></i></span>
            </div>
          </div>
          <div class="mb-3" *ngIf="show?.organizing_country === 'CAN'">
            <label class="form-label">EC</label>
            <div class="input-group">
              <input type="text" class="form-control" formControlName="EC" (blur)="getPersonECData($any($event.target).value)">
              <span class="input-group-text" *ngIf="loadingEC"><i class="fas fa-spinner fa-spin"></i></span>
            </div>
          </div>
          <div class="mb-3">
            <label class="form-label">Address <span class="text-danger">*</span></label>
            <input type="text" class="form-control" formControlName="address" required>
          </div>
          <div class="mb-3">
            <label class="form-label">Address 2</label>
            <input type="text" class="form-control" formControlName="address2">
          </div>
          <div class="row">
            <div class="col-md-6 mb-3">
              <label class="form-label">City <span class="text-danger">*</span></label>
              <input type="text" class="form-control" formControlName="city" required>
            </div>
            <div class="col-md-6 mb-3">
              <label class="form-label">State <span class="text-danger">*</span></label>
              <input type="text" class="form-control" formControlName="state" required>
            </div>
          </div>
          <div class="row">
            <div class="col-md-6 mb-3">
              <label class="form-label">ZIP <span class="text-danger">*</span></label>
              <input type="text" class="form-control" formControlName="zip" required>
            </div>
            <div class="col-md-6 mb-3">
              <label class="form-label">Country <span class="text-danger">*</span></label>
              <input type="text" class="form-control" formControlName="country" required>
            </div>
          </div>
        </form>
      </div>
      <div class="modal-footer">
        <button type="button" class="btn btn-light" data-bs-dismiss="modal">Cancel</button>
        <button type="button" class="btn btn-primary" (click)="saveEdit()">Save changes</button>
      </div>
    </div>
  </div>
</div>