<footer class="footer">
  <div class="container-fluid">
    <div class="row">
      <div class="col-md-6">
        {{ currentYear }} © Equestrian Digital 
          <span class="logo-lg">
              <img src="assets/images/logo-dark.png" alt="dark logo">
          </span>
         
      </div>
      <div class="col-md-6 text-md-end">
        <span class="my-auto"><small>v{{ version }}</small></span>
        <!-- <div class="footer-links d-none d-md-block">
          <a href="javascript: void(0);" class="me-3">About</a>
          <a href="javascript: void(0);" class="me-3">Support</a>
          <a href="javascript: void(0);">Contact Us</a>
        </div> -->
      </div>
    </div>
  </div>
</footer> 