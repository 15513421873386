import { Component, OnInit } from '@angular/core';
import { Router, NavigationEnd } from '@angular/router';
import { filter } from 'rxjs/operators';
import { ShowsService } from '../services/shows.service';
import { environment } from './../../environments/environment';

@Component({
  selector: 'app-left-menu',
  templateUrl: './left-menu.component.html',
  styleUrls: ['./left-menu.component.scss']
})

export class LeftMenuComponent implements OnInit {
  public reining: boolean;
  public tc: boolean;
  public addEntryRoute: string;
  public checkoutRoute: string;
  public showID: number;
  public organizer: number;
  public currentUrl: string = '';
  public show: any;
  public username: string = '';
  public showName: string = '';
  public version: string = environment.version;

  constructor(
    private router: Router,
    private showsService: ShowsService
  ) {
    this.organizer = 0;
    this.reining = sessionStorage.getItem('reining') === '1';
    this.tc = sessionStorage.getItem('tc') === '1';
    this.addEntryRoute = this.reining ? '/add-entry' : '/create-entry';
    
    // Default checkout route based on reining status
    this.checkoutRoute = this.reining ? '/checkout-reining' : '/checkout';
    
    // Get user info from storage
    this.username = localStorage.getItem('username') || (sessionStorage.getItem('username') || '');
    this.showName = sessionStorage.getItem('showname') || '';
    
    // Get Session Storage value for club_id
    const clubId = sessionStorage.getItem('club_id');
    const showId = sessionStorage.getItem('showID');
    if (clubId !== null) {
      this.organizer = parseInt(clubId);
    }
    if(showId !== null){
      this.showID = parseInt(showId);
    }
    
    // Subscribe to router events to track the current URL
    this.router.events.pipe(
      filter(event => event instanceof NavigationEnd)
    ).subscribe((event: any) => {
      this.currentUrl = event.url;
    });
  }
  
  ngOnInit() {
    // Get show details to determine payment gateway
    if (this.showID) {
      this.getShowDetails();
    }
  }
  
  private getShowDetails() {
    this.showsService.getShowNest(this.showID.toString()).subscribe({
      next: (response: any) => {
        if (response && response.data && response.data.concurso) {
          this.show = response.data.concurso;
          
          // Update checkout route based on payment gateway
          if (this.show.payment_gateway === 'betterpay') {
            this.checkoutRoute = '/betterpay-checkout';
          } else if (this.reining) {
            this.checkoutRoute = '/checkout-reining';
          } else {
            this.checkoutRoute = '/checkout';
          }
        }
      },
      error: (error) => {
        console.error('Error fetching show details:', error);
      }
    });
  }

  /**
   * Log out the user and redirect to login page
   */
  public logOut(): void {
    sessionStorage.clear();
    localStorage.clear();
    this.router.navigate(['/login']);
  }
}
