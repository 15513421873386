<div class="leftside-menu">
  <!-- Mobile close button -->
  <button class="btn-close float-end d-lg-none mt-2 me-2 button-close-fullsidebar" aria-label="Close menu"></button>

  <!-- Brand Logo Light -->
  <a [routerLink]="['/']" class="logo logo-light">
    <span class="logo-lg">
      <img src="assets/images/logo.png" alt="logo">
    </span>
    <span class="logo-sm">
      <img src="assets/images/logo-sm.png" alt="small logo">
    </span>
  </a>

  <!-- Brand Logo Dark -->
  <a [routerLink]="['/']" class="logo logo-dark">
    <span class="logo-lg">
      <img src="assets/images/logo-dark.png" alt="dark logo">
    </span>
    <span class="logo-sm">
      <img src="assets/images/logo-dark-sm.png" alt="small logo">
    </span>
  </a>

  <!-- Mobile user info -->
  <div class="d-flex align-items-center p-3 d-lg-none border-bottom">
    <div class="flex-grow-1 ms-3">
      <small class="text-muted">Show: {{ showName }}</small>
    </div>
  </div>

  <!-- Sidebar -left -->
  <div class="h-100" id="leftside-menu-container" data-simplebar>
    <!-- Sidemenu -->
    <ul class="side-nav">
      <li class="side-nav-title side-nav-item">Menu</li>
      
      <li class="side-nav-item">
        <a [routerLink]="['/']" routerLinkActive="active-menu-item" [routerLinkActiveOptions]="{exact: true}" class="side-nav-link">
          <i class="mdi mdi-format-list-bulleted"></i>
          <span> My entries </span>
        </a>
      </li>
      
      <li class="side-nav-item">
        <a [routerLink]="['/catalog']" routerLinkActive="active-menu-item" class="side-nav-link">
          <i class="mdi mdi-account-settings"></i>
          <span> My Catalog </span>
        </a>
      </li>
      
      <!-- <li class="side-nav-item">
        <a [routerLink]="['/add-drops']" routerLinkActive="active-menu-item" class="side-nav-link">
          <i class="mdi mdi-swap-horizontal"></i>
          <span> Add/Drops </span>
        </a>
      </li> -->
      
      <li class="side-nav-item">
        <a [routerLink]="[addEntryRoute]" routerLinkActive="active-menu-item" class="side-nav-link">
          <i class="mdi mdi-horse-human"></i>
          <span> Add entry </span>
        </a>
      </li>
      
      <li class="side-nav-item" *ngIf="reining">
        <a [routerLink]="['/trainer-reservation']" routerLinkActive="active-menu-item" class="side-nav-link">
          <i class="mdi mdi-format-list-bulleted"></i>
          <span> Trainer Reservation </span>
        </a>
      </li>
      
      <!-- <li class="side-nav-item" *ngIf="!reining">
        <a [routerLink]="['/stalls']" routerLinkActive="active-menu-item" class="side-nav-link">
          <i class="mdi mdi-table"></i>
          <span> Stalls </span>
        </a>
      </li>
      
      <li class="side-nav-item">
        <a routerLink="/feed-orders" routerLinkActive="active-menu-item" class="side-nav-link">
          <i class="mdi mdi-format-list-bulleted"></i>
          <span> Feed Orders </span>
        </a>
      </li> -->
      
      <!-- <li class="side-nav-item">
        <a routerLink="/trainer-list" routerLinkActive="active-menu-item" class="side-nav-link">
          <i class="mdi mdi-calendar"></i>
          <span> My Schedule </span>
        </a>
      </li> -->
      
      <li class="side-nav-item">
        <a [routerLink]="[checkoutRoute]" routerLinkActive="active-menu-item" class="side-nav-link">
          <i class="mdi mdi-credit-card"></i>
          <span> Pay Entries </span>
          <small *ngIf="!show" class="ms-1">
            <i class="mdi mdi-loading mdi-spin text-muted"></i>
          </small>
        </a>
      </li>
      
      <li class="side-nav-item">
        <a [routerLink]="['/history']" routerLinkActive="active-menu-item" class="side-nav-link">
          <i class="mdi mdi-book-open-variant"></i>
          <span> History </span>
        </a>
      </li>
      
      <!-- Mobile-only menu items -->
      <li class="side-nav-item d-lg-none">
        <div class="side-nav-title side-nav-item">Account</div>
        <a [routerLink]="['/select-show']" class="side-nav-link">
          <i class="mdi mdi-swap-horizontal"></i>
          <span> Change Show </span>
        </a>
      </li>
      
      <li class="side-nav-item d-lg-none">
        <a href="javascript:void(0);" (click)="logOut()" class="side-nav-link">
          <i class="mdi mdi-logout"></i>
          <span> Logout </span>
        </a>
      </li>
    </ul>
    
    <!-- Help box in sidebar -->
    <!-- <div class="help-box text-white p-3 mt-3 mx-3 d-none d-lg-block">
      <p class="mb-1">Need Help?</p>
      <p class="font-size-12 mb-3">Contact support for assistance with your entries</p>
      <a href="mailto:support@equestriandigital.com" class="btn btn-outline-light btn-sm">Contact Support</a>
    </div> -->
    
    <!-- Version info -->
    <div class="text-center text-muted mt-2 d-none d-lg-block">
      <small>v{{ version }}</small>
    </div>
  </div>
</div>
