import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from '../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class BetterpayService {
  private apiUrl = environment.api.nest.host;

  constructor(private http: HttpClient) { }

  /**
   * Initialize a Betterpay payment
   * @param amount The amount to charge
   * @param metadata Additional metadata for the payment
   * @param description Description of the payment
   */
  initializePayment(amount: number, metadata: any, description: string): Observable<any> {
    return this.http.post(`${this.apiUrl}/betterpay/initialize`, {
      amount,
      metadata,
      description
    });
  }

  /**
   * Process a Betterpay payment
   * @param paymentData Payment information including token from Betterpay
   */
  processPayment(paymentData: any): Observable<any> {
    return this.http.post(`${this.apiUrl}/betterpay/process`, paymentData);
  }

  /**
   * Process a credit card payment using Collect.js
   * @param paymentData Payment information including token from Collect.js
   */
  processCardPayment(paymentData: any): Observable<any> {
    return this.http.post(`${this.apiUrl}/betterpay/process-card`, paymentData);
  }

  /**
   * Verify the status of a Betterpay payment
   * @param paymentId The ID of the payment to verify
   */
  verifyPayment(paymentId: string): Observable<any> {
    return this.http.get(`${this.apiUrl}/betterpay/verify/${paymentId}`);
  }

  /**
   * Transact a payment
   * @param paymentData Payment information including token from Collect.js
   */
  transactPayment(paymentData: any): Observable<any> {
    return this.http.post(`${environment.api.nest.host}betterpay/transact`, paymentData);
  }

  /**
   * Get Saved Cards for User
   * @param email The email of the user to get saved cards for
   */
  getSavedCards(email: string, club_id: string): Observable<any> {
    return this.http.get(`${environment.api.nest.host}betterpay/cardsByEmail/?email=${email}&club_id=${club_id}`);
  }

  /**
   * Process a payment using a saved card
   * @param paymentData Payment information including saved card details
   */
  processSavedCardPayment(paymentData: any): Observable<any> {
    return this.http.post(`${this.apiUrl}/betterpay/process-saved-card`, paymentData);
  }
}
