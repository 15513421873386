import { Component, OnInit } from '@angular/core';
import { environment } from 'src/environments/environment';
import { ActivatedRoute } from '@angular/router';
import { EntriesService } from './../services/entries.service';
import { firstValueFrom } from 'rxjs';

@Component({
  selector: 'app-entry-invoice',
  templateUrl: './entry-invoice.component.html',
  styleUrls: ['./entry-invoice.component.scss']
})
export class EntryInvoiceComponent implements OnInit {
  public entry: string;
  public idConcurso: string;
  public version: string;
  public date: Date;
  public entryInfo: any;
  public classDivisionFees: any[];
  public charges: any[];
  public totalCharges: number;
  public totalClassDivisionFees: number;
  public feedOrders: any[];
  public totalFeedOrders: number;
  public splits: any[];
  public totalSplits: number;
  public payments: any[];
  public totalPayments: number;
  public totalProcessingFees: number = 0;

  constructor(private _route: ActivatedRoute, private _entry: EntriesService) {
    this.idConcurso = this._route.snapshot.params['idConcurso'];
    this.entry = this._route.snapshot.params['entry'];
    this.version = environment.version;
    this.date = new Date();
    this.entryInfo = {};
    this.classDivisionFees = [];
    this.charges = [];
    this.totalCharges = 0;
    this.totalClassDivisionFees = 0;
    this.feedOrders = [];
    this.totalFeedOrders = 0;
    this.splits = [];
    this.totalSplits = 0;
    this.payments = [];
    this.totalPayments = 0;
  }

  async ngOnInit() {
    this.entryInfo = await firstValueFrom(this._entry.getEntryInvoiceInfo(this.entry, this.idConcurso));
    const { charges, classDivisionFees, totalCharges, totalClassDivisionFees } = await firstValueFrom(this._entry.getEntryInvoiceCharges(this.entry, this.idConcurso)).then(r => ({ charges: r.cargos, classDivisionFees: r.cargosInscripciones, totalCharges: r.totalCargos, totalClassDivisionFees: r.totalInscripciones }));
    this.classDivisionFees = classDivisionFees;
    this.charges = charges;
    this.totalCharges = totalCharges;
    this.totalClassDivisionFees = totalClassDivisionFees;
    this.feedOrders = await firstValueFrom(this._entry.getEntryInvoiceFeedOrders(this.entry, this.idConcurso)).then(r => r.summary);
    this.totalFeedOrders = this.feedOrders.reduce((a, b) => {
      return a + b.total;
    }, 0);
    const { totalSplits, splits } = await firstValueFrom(this._entry.getEntryInvoiceSplits(this.entry, this.idConcurso)).then(r => ({ totalSplits: r.totalSplits, splits: r.splits }));
    this.splits = splits;
    this.totalSplits = totalSplits;
    const { totalPagos, pagos } = await firstValueFrom(this._entry.getEntryInvoicePayments(this.entry, this.idConcurso)).then(r => ({ totalPagos: r.totalPagos, pagos: r.pagos.filter((p: any) => !p.anulado) }));
    this.payments = pagos;
    
    // Calculate total payments including processing fees
    this.totalPayments = this.payments.reduce((total, payment) => {
      const processingFee = payment.processing_fee || 0;
      return total + Number(payment.monto) + Number(processingFee);
    }, 0);
    
    // Calculate total processing fees
    this.totalProcessingFees = this.payments.reduce((total, payment) => {
      return total + Number(payment.processing_fee || 0);
    }, 0);
  }
}
