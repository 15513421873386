<div class="navbar-custom">
    <div class="topbar container-fluid">
        <!-- Left section with logo and menu toggle -->
        <div class="d-flex align-items-center">
            <!-- Sidebar Menu Toggle Button - only visible on mobile -->
            <button class="button-toggle-menu d-lg-none me-2">
                <i class="mdi mdi-menu"></i>
            </button>

            <!-- Show name - visible on all screens -->
            <div class="ms-3 d-none d-sm-block">
                <h6 class="mb-0 text-truncate show-name">{{ showName }}</h6>
            </div>
        </div>

        <!-- Center section - only visible on larger screens -->
        <div class="d-none d-lg-flex align-items-center justify-content-center flex-grow-1">
            <button class="btn btn-primary" [routerLink]="['/select-show']" style="cursor: pointer;">
                <i class="fas fa-exchange-alt"></i>
                &nbsp;Change Show
            </button>
        </div>

        <!-- Right section with user menu -->
        <div class="d-flex align-items-center">
            <!-- Mobile action buttons -->
            <div class="d-flex d-lg-none me-2">
                <button class="btn btn-sm btn-primary me-2" [routerLink]="['/select-show']">
                    <i class="fas fa-exchange-alt"></i> Change Show
                </button>
                <!-- <button class="btn btn-sm btn-outline-primary" [routerLink]="[checkoutRoute]">
                    <i class="mdi mdi-credit-card"></i>
                </button> -->
            </div>

            <!-- User dropdown -->
            <!-- <div class="dropdown">
                <a class="nav-link dropdown-toggle arrow-none nav-user px-2" data-bs-toggle="dropdown" href="#"
                    role="button" aria-haspopup="false" aria-expanded="false">
                    <span class="account-user-avatar">
                        <img src="assets/images/logo-dark.png" alt="user-image" width="32" class="rounded-circle">
                    </span>
                    <span class="d-lg-flex flex-column gap-1 d-none">
                        <h5 class="my-0">{{ username }}</h5>
                        <h6 class="my-0 fw-normal">{{ email }}</h6>
                    </span>
                </a>
                <div class="dropdown-menu dropdown-menu-end dropdown-menu-animated profile-dropdown">
                    <div class="dropdown-header noti-title">
                        <h6 class="text-overflow m-0">Welcome!</h6>
                    </div>
                    <a href="javascript:void(0);" class="dropdown-item" routerLink="/select-show">
                        <i class="mdi mdi-swap-horizontal me-1"></i>
                        <span>Change Show</span>
                    </a>
                    <a href="javascript:void(0);" class="dropdown-item" [routerLink]="[checkoutRoute]">
                        <i class="mdi mdi-credit-card me-1"></i>
                        <span>Pay Entries</span>
                    </a>
                    <div class="dropdown-divider"></div>
                    <a href="javascript:void(0);" class="dropdown-item" (click)="logOut()">
                        <i class="mdi mdi-logout me-1"></i>
                        <span>Logout</span>
                    </a>
                </div>
            </div> -->
        </div>
        <div class="d-flex align-items-right">
            <button class="btn btn-primary" (click)="logOut()" style="cursor: pointer;">
                <i class="fas fa-sign-out-alt"></i>
                &nbsp;Logout
            </button>
        </div>
    </div>
</div>