import { Component } from '@angular/core';
import { successNotification, errorNotification } from '../services/notifications.service';
import { HorsesService } from '../services/horses.service';
import { PersonsService } from '../services/persons.service';
import { ShowsService } from '../services/shows.service';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { firstValueFrom } from 'rxjs';

declare var $: any;  // Add this at the top for jQuery

@Component({
  selector: 'app-my-catalog',
  templateUrl: './my-catalog.component.html',
  styleUrls: ['./my-catalog.component.scss']
})
export class MyCatalogComponent {
  public email: string;
  public uid: string;
  public show: any;
  public showID: string;
  horses: Array<any> = [];
  riders: Array<any> = [];
  trainers: Array<any> = [];
  owners: Array<any> = [];
  public itemToDelete: any = null;

  // Add new properties
  public actions = {
    modalForm: '',
    mode: 'create' // or 'edit'
  };

  public horseForm: FormGroup;
  public personForm: FormGroup;

  // Add loading flags
  public loadingUSEF: boolean = false;
  public loadingFEI: boolean = false;
  public loadingEC: boolean = false;

  constructor(
    private _horsesService: HorsesService,
    private _personsService: PersonsService,
    private showsService: ShowsService
  ) {
    this.email = localStorage.getItem('email') || (sessionStorage.getItem('email') || '');
    this.uid = localStorage.getItem('user_document_id') || '';
    this.showID = sessionStorage.getItem('showID') || '';

    this.horseForm = new FormGroup({
      uid: new FormControl(''),
      FEI: new FormControl('', []),
      USEF: new FormControl('', []),
      EC: new FormControl('', [
        Validators.pattern('^[0-9]*$'),
        Validators.maxLength(8),
        Validators.minLength(1)
      ]),
      name: new FormControl('', [Validators.required])
    });

    this.personForm = new FormGroup({
      uid: new FormControl(''),
      USEF: new FormControl('', []),
      FEI: new FormControl('', []),
      EC: new FormControl('', []),
      firstname: new FormControl('', [Validators.required]),
      lastname: new FormControl('', [Validators.required]),
      address: new FormControl('', [Validators.required]),
      address2: new FormControl('', []),
      phonenumber: new FormControl('', [Validators.required]),
      city: new FormControl('', [Validators.required]),
      state: new FormControl('', [Validators.required]),
      zip: new FormControl('', [Validators.required]),
      country: new FormControl('', [Validators.required])
    });
  }

  ngOnInit() {
    this._personsService.getDocumentByEmail(this.email).then((doc: any) => {
      this.uid = doc.uid;
      localStorage.setItem('user_document_id', this.uid);
      this.getShow();
      this.getHorsesSuggestions();
      this.getPeopleSuggestions();
    });
  }

  public async getHorsesSuggestions() {
    try {
      this.horses = await this._horsesService.getHorsesSuggestions(this.uid);
      console.log('Horses: ', this.horses);
    } catch (reason: any) {
      errorNotification(reason.error, `Error horses. ${reason.error}`);
      return;
    }
  }

  public async getPeopleSuggestions() {
    try {
      console.log('Getting people suggestions for uid:', this.uid);
      
      this.riders = await this._personsService.getPersonsSuggestions(this.uid, 'rider');
      console.log('Riders raw data: ', this.riders);
      
      this.trainers = await this._personsService.getPersonsSuggestions(this.uid, 'trainer');
      console.log('Trainers raw data: ', this.trainers);
      
      this.owners = await this._personsService.getPersonsSuggestions(this.uid, 'owner');
      console.log('Owners raw data: ', this.owners);
      
      console.log('Combined allPersons:', this.allPersons);
    } catch (reason: any) {
      console.error('Error getting people suggestions:', reason);
      errorNotification(reason.error, `Error riders. ${reason.error}`);
      return;
    }
  }

  public deleteHorse(horse: any) {
    this.itemToDelete = { 
      type: 'horse',
      data: horse 
    };
    $('#deleteModal').modal('show');
  }

  public deletePerson(uid: string, roles: string[]) {
    this.itemToDelete = { 
      type: 'person',
      data: uid,
      roles: roles 
    };
    $('#deleteModal').modal('show');
  }

  public async confirmDelete() {
    if (!this.itemToDelete) return;

    try {
      if (this.itemToDelete.type === 'horse') {
        await this._horsesService.deleteHorseSuggestion(this.uid, this.itemToDelete.data);
        successNotification('Horse deleted', 'Horse deleted successfully');
        await this.getHorsesSuggestions();
      } else if (this.itemToDelete.type === 'person') {
        await Promise.all(
          this.itemToDelete.roles.map((role: string) => 
            this._personsService.deletePersonSuggestion(this.uid, this.itemToDelete.data, role)
          )
        );
        successNotification('Person deleted', 'Person deleted successfully');
        await this.getPeopleSuggestions();
      }
      $('#deleteModal').modal('hide');
    } catch (error: any) {
      errorNotification(error.error?.code || 'Error', error.error?.message || 'Failed to delete item');
    } finally {
      this.itemToDelete = null;
    }
  }

  // Add getter for combined people list
  get allPersons() {
    const allPersons = [
      ...this.riders,
      ...this.trainers,
      ...this.owners
    ];
    
    // Remove duplicates by USEF number, fallback to uid
    return Array.from(
      new Map(
        allPersons.map(p => [p.USEF || p.uid, p])
      ).values()
    );
  }

  getRoles(person: any): string[] {
    const roles: string[] = [];
    if (this.trainers.find(t => t.uid === person.uid)) roles.push('trainer');
    if (this.riders.find(r => r.uid === person.uid)) roles.push('rider');
    if (this.owners.find(o => o.uid === person.uid)) roles.push('owner');
    return roles;
  }

  isTrainer(person: any): boolean {
    return this.trainers.some(t => t.uid === person.uid);
  }

  isRider(person: any): boolean {
    return this.riders.some(r => r.uid === person.uid);
  }

  isOwner(person: any): boolean {
    return this.owners.some(o => o.uid === person.uid);
  }

  public async getShow() {
    this.showsService.getShowById(this.showID).subscribe({
      next: (response: any) => {
        if (response.error) {
          errorNotification(response.error.code, response.error.message);
          return;
        }
        this.show = response.data.concurso;
      },
      error: (error) => {
        console.error('Error fetching show:', error);
        errorNotification('Error', 'Failed to fetch show details');
      }
    });
  }

  public editHorse(horse: any) {
    this.actions.modalForm = 'horse';
    this.actions.mode = 'edit';
    // Store the uid for update
    this.horseForm.patchValue({
      ...horse,
      uid: horse.uid // Make sure we keep the uid
    });
    $('#formModal').modal('show');
  }

  public editPerson(person: any) {
    this.actions.modalForm = 'person';
    this.actions.mode = 'edit';
    // Store the uid for update
    this.personForm.patchValue({
      ...person,
      uid: person.uid // Make sure we keep the uid
    });
    $('#formModal').modal('show');
  }

  public async saveEdit() {
    if (this.actions.modalForm === 'horse') {
      if (!this.horseForm.valid) {
        errorNotification('Validation Error', 'Please fill in all required fields');
        return;
      }
      try {
        const horseData = this.horseForm.value;
        await this._horsesService.updateHorseSuggestion(
          this.uid,
          horseData
        );
        successNotification('Success', 'Horse updated successfully');
        await this.getHorsesSuggestions();
        $('#formModal').modal('hide');
      } catch (error: any) {
        errorNotification('Error', error.message || 'Failed to update horse');
      }
    } else if (this.actions.modalForm === 'person') {
      if (!this.personForm.valid) {
        errorNotification('Validation Error', 'Please fill in all required fields');
        return;
      }
      try {
        const personData = this.personForm.value;
        const roles = this.getRoles(personData);
        
        if (roles.length === 0) {
          errorNotification('Error', 'Person must have at least one role');
          return;
        }

        await Promise.all(
          roles.map(role => 
            this._personsService.updatePersonSuggestion(
              this.uid,
              personData.uid,
              role,
              personData
            )
          )
        );
        
        successNotification('Success', 'Person updated successfully');
        await this.getPeopleSuggestions();
        $('#formModal').modal('hide');
      } catch (error: any) {
        errorNotification('Error', error.message || 'Failed to update person');
      }
    }
  }

  // Add search methods for horses
  public async getHorseUSEFData(usef: string) {
    if (!usef) return;
    this.loadingUSEF = true;
    try {
      const response: any = await firstValueFrom(this._horsesService.getHorseByUSEF(usef, this.showID));
      if (response.error || !response.usefData) {
        errorNotification('Error', 'Horse USEF number not found');
        return;
      }

      console.log('response.usefData', response.usefData);
      
      // Create update object with required fields
      const updateData: any = {
        name: response.usefData.name
      };

      // Only add FEI if it has a value
      if (response.usefData.fei) {
        updateData.FEI = response.usefData.fei;
      }

      this.horseForm.patchValue(updateData);
    } catch (error: any) {
      errorNotification('Error', error.message || 'Failed to fetch horse USEF data');
    } finally {
      this.loadingUSEF = false;
    }
  }

  public async getHorseFEIData(fei: string) {
    if (!fei) return;
    this.loadingFEI = true;
    try {
      const response: any = await firstValueFrom(this._horsesService.getHorseByFEI(fei));
      if (response.error || !response.caballo) {
        errorNotification('Error', 'Horse FEI number not found');
        return;
      }

      console.log('response.caballo', response.caballo);

      // Create update object with required fields
      const updateData: any = {
        name: response.caballo.name
      };

      this.horseForm.patchValue(updateData);
    } catch (error: any) {
      errorNotification('Error', error.message || 'Failed to fetch horse FEI data');
    } finally {
      this.loadingFEI = false;
    }
  }

  public async getHorseECData(ec: string) {
    if (!ec || ec.length > 8 || !/^\d+$/.test(ec)) {
      errorNotification('Error', 'EC number must be between 1-8 digits');
      return;
    }
    this.loadingEC = true;
    try {
      const response = await firstValueFrom(this._horsesService.getHorseECData(ec));
      const ecData = response as { name: string };
      if (!ecData.name) {
        errorNotification('Error', 'Horse EC number not found');
        return;
      }
      this.horseForm.patchValue({
        name: ecData.name,
        EC: ec
      });
    } catch (error: any) {
      errorNotification('Error', error.message || 'Failed to fetch horse EC data');
    } finally {
      this.loadingEC = false;
    }
  }

  // Add search methods for people
  public async getPersonUSEFData(usef: string) {
    if (!usef) return;
    this.loadingUSEF = true;
    try {
      const response: any = await firstValueFrom(this._personsService.getUsefData(usef));
      if (response.error || !response.usefData) {
        errorNotification('Error', 'Person USEF number not found');
        return;
      }

      console.log('response.usefData', response.usefData);

      // Create update object with required fields
      const updateData: any = {
        firstname: response.usefData.name,
        lastname: response.usefData.lastname,
      };

      // Only add FEI if it has a value
      if (response.usefData.fei) {
        updateData.FEI = response.usefData.fei;
      }

      this.personForm.patchValue(updateData);
    } catch (error: any) {
      errorNotification('Error', error.message || 'Failed to fetch person USEF data');
    } finally {
      this.loadingUSEF = false;
    }
  }

  public async getPersonFEIData(fei: string) {
    if (!fei) return;
    this.loadingFEI = true;
    try {
      const response: any = await firstValueFrom(this._personsService.getPersonByFEI(fei));
      if (response.error) {
        errorNotification('Error', 'Person FEI number not found');
        return;
      }
      this.personForm.patchValue({
        firstname: response.firstname,
        lastname: response.lastname,
        FEI: response.fei
      });
    } catch (error: any) {
      errorNotification('Error', error.message || 'Failed to fetch person FEI data');
    } finally {
      this.loadingFEI = false;
    }
  }

  public async getPersonECData(ec: string) {
    if (!ec || ec.length > 8 || !/^\d+$/.test(ec)) {
      errorNotification('Error', 'EC number must be between 1-8 digits');
      return;
    }
    this.loadingEC = true;
    try {
      const response: any = await firstValueFrom(this._personsService.getECData(ec));
      if (!response) {
        errorNotification('Error', 'Person EC number not found');
        return;
      }
      this.personForm.patchValue({
        firstname: response.firstName,
        lastname: response.lastName,
        EC: ec
      });
    } catch (error: any) {
      errorNotification('Error', error.message || 'Failed to fetch person EC data');
    } finally {
      this.loadingEC = false;
    }
  }
}
