import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from '../../environments/environment';
import { Observable, catchError, tap } from 'rxjs';
import { map } from 'rxjs/operators';
import { AuthService } from './auth.service';
@Injectable({
  providedIn: 'root'
})
export class ShowsService {
  private apiUrl = environment.api.nest.host;

  constructor(private http: HttpClient, private authService: AuthService) { }

  getShows(): Observable<any> {
    return this.http.get(`${this.apiUrl}concursos`).pipe(
      tap(response => console.log('Shows response:', response)),
      map((response: any) => {
        return response.concursos.map((show: any) => ({
          ...show,
          inicio: show.inicio.replace(/-/g, '/'),
          fin: show.fin.replace(/-/g, '/'),
        }));
      }),
      catchError(error => {
        console.error('Error fetching shows:', error);
        throw error;
      })
    );
  }

  getShowById(showId: string): Observable<any> {
    const headers = new HttpHeaders({
      'x-api-key': environment.api.ed.key,
      'content-Type': 'application/json',
    });

    const url = `${this.apiUrl}concursos/${showId}`;    
    return this.http.get(url, { headers: headers }).pipe(
      catchError(error => {
        console.error('Error fetching show details:', error);
        throw error;
      })
    );
  }

  getShowNest(showId: string): Observable<any> {
    const headers = new HttpHeaders({
      'x-api-key': environment.api.ed.key,
      'content-Type': 'application/json', 
    });
    return this.http.get(`${environment.api.nest.host}concursos/${showId}`, { headers: headers }).pipe(
      catchError(error => {
        console.error('Error fetching show details:', error);
        throw error;
      })
    );
  }
  // Add more show-related methods as needed
} 