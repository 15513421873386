<div class="wrapper">
  <app-top-menu></app-top-menu>
  <app-left-menu></app-left-menu>
  <div class="content-page">
    <div class="container-fluid">
      <div id="agreementModal" class="modal fade" tabindex="-1" role="dialog" aria-labelledby="agreementModalLabel"
        aria-hidden="true">
        <div class="modal-dialog modal-full-width">
          <div class="modal-content">
            <div class="modal-header">
              <h4 class="modal-title" id="agreementModalLabel">FEDERATION ENTRY AGREEMENT</h4>
              <button type="button" class="btn close" data-dismiss="modal" aria-hidden="true">×</button>
            </div>
            <div class="modal-body">

            </div>
            <div class="modal-footer">
              <button type="button" class="btn btn-light" data-dismiss="modal" (click)="hideModal()">Close</button>
            </div>
          </div><!-- /.modal-content -->
        </div><!-- /.modal-dialog -->
      </div>

      <div id="formModal" class="modal fade" tabindex="-1" role="dialog" aria-labelledby="standard-modalLabel"
        aria-hidden="true">
        <div class="modal-dialog">
          <div class="modal-content">
            <div class="modal-header">
              <h4 class="modal-title" id="standard-modalLabel">New {{ actions.modalForm }}</h4>
              <button type="button" class=" btn close" data-bs-dismiss="modal" aria-hidden="true">X</button>
            </div>
            <div class="modal-body">
              <p> <strong>Suggestions:</strong> Type in NRHA number, then hit tab or enter. Correct or add information as needed</p>
              <div class="col-12" *ngIf="actions.modalForm == 'trainer'">
                <form class="row row-cols-lg-12 col-12 g-3 align-items-center needs-validation"
                  [formGroup]="trainerForm" autocomplete="off" (ngSubmit)="saveSuggestions($event)">
                  <div class="col-sm-12 col-md-6">
                    <div class="input-group flex-nowrap">
                      <span class="input-group-text" *ngIf="loadingNrha">
                        <div class="spinner-border spinner-border-sm" role="status"></div>
                      </span>
                      <input (change)="getPersonByNrha($any($event).target.value, 'trainer')" type="text" class="form-control" id="floatingNrhaTrainer" placeholder="NRHA #" formControlName="nrha" required="" />
                    </div>
                  </div>

                  <div class="col-sm-12 col-md-6">
                    <input type="text" class="form-control" id="floatingTrainerfirsname" placeholder="Firstname" formControlName="firstname" required />
                  </div>

                  <div class="col-sm-12 col-md-6">
                    <input type="text" class="form-control" id="floatingTrainerlastname" placeholder="Lastname" formControlName="lastname" required />
                  </div>

                  <div class="col-sm-12 col-md-6">
                    <input type="text" class="form-control" id="floatingTrainerPhoneNumber" placeholder="Phone number" formControlName="phonenumber" required />
                  </div>

                  <!-- Address -->
                  <div class="col-sm-12 col-md-12">
                    <autocomplete-addresss (setAddress)="getAddress($event, 'trainer')" adressType="geocode"></autocomplete-addresss>
                  </div>

                  <div class="col-sm-12 col-md-12">
                    <input type="text" class="form-control" placeholder="Address2" formControlName="address2" />
                  </div>

                  <div class="col-sm-12 col-md-6">
                    <input type="text" class="form-control" placeholder="City" formControlName="city" required="" />
                  </div>

                  <div class="col-sm-12 col-md-6">
                    <input type="text" class="form-control" placeholder="State" formControlName="state" required="" />
                  </div>

                  <div class="col-sm-12 col-md-6">
                    <input type="text" class="form-control" placeholder="Zip" formControlName="zip" required="" />
                  </div>

                  <div class="col-sm-12 col-md-6">
                    <input type="text" class="form-control" placeholder="Country" formControlName="country" required="" />
                  </div>
                  <!-- End Address -->

                  <div class="modal-footer">
                    <button type="button" class="btn btn-light" data-bs-dismiss="modal">Close</button>
                    <button type="submit" class="btn btn-primary">Save & select</button>
                  </div>
                </form>
              </div>
              <div class="col-12" *ngIf="actions.modalForm == 'horse'">
                <form class="row row-cols-lg-12 g-3 align-items-center needs-validation" [formGroup]="horseForm" autocomplete="off" (ngSubmit)="saveSuggestions($event)">
                  <div class="col-sm-12 col-md-6">
                    <div class="input-group flex-nowrap">
                      <span class="input-group-text" *ngIf="loadingNrha">
                        <div class="spinner-border spinner-border-sm" role="status"></div>
                      </span>
                      <input (change)="getHorseByNrha()" type="text" class="form-control" id="floatingNrha" placeholder="NRHA #" formControlName="nrha" required/>
                    </div>
                  </div>
                  <div class="col-sm-12 col-md-6">
                    <input type="text" class="form-control" id="floatingHorse" placeholder="Horse name" formControlName="name" required />
                  </div>
                  <div class="col-sm-12 col-md-6">
                    <input type="text" class="form-control" id="yearFoaledHorse" placeholder="Year Foaled" formControlName="yearFoaled" required />
                  </div>

                  <div class="col-sm-12 col-md-6">
                    <select class="form-control" id="genderHorse" formControlName="gender" required>
                      <option value="">Mare, Gelding or Stallion</option>
                      <option value="mare">Mare</option>
                      <option value="gelding">Gelding</option>
                      <option value="stallion">Stallion</option>
                    </select>
                  </div>
                  <div class="col-sm-12 col-md-6">
                    <input type="text" class="form-control" id="sireHorse" placeholder="Sire name" formControlName="sire" required />
                  </div>
                  <div class="col-sm-12 col-md-6">
                    <input type="text" class="form-control" id="damHorse" placeholder="Dam name" formControlName="dam" required />
                  </div>
                  <div class="modal-footer">
                    <button type="button" class="btn btn-light" data-bs-dismiss="modal">Close</button>
                    <button type="submit" class="btn btn-primary">Save & select</button>
                  </div>
                </form>
              </div>
              <div class="col-12" *ngIf="actions.modalForm == 'owner'">
                <form class="row row-cols-lg-12 g-3 align-items-center needs-validation" [formGroup]="ownerForm" autocomplete="off" (ngSubmit)="saveSuggestions($event)">
                  <div class="col-sm-12 col-md-6">
                    <div class="input-group flex-nowrap">
                      <span class="input-group-text" *ngIf="loadingNrha">
                        <div class="spinner-border spinner-border-sm" role="status"></div>
                      </span>
                      <input (change)="getPersonByNrha($any($event).target.value, 'owner')" type="text" class="form-control" id="floatingNrhaOwner" placeholder="NRHA #" formControlName="nrha" required/>
                    </div>
                  </div>

                  <div class="col-sm-12 col-md-6">
                    <input type="text" class="form-control" id="floatingOwnerfirsname" placeholder="Firstname" formControlName="firstname" required />
                  </div>

                  <div class="col-sm-12 col-md-6">
                    <input type="text" class="form-control" id="floatingOwnerlastname" placeholder="Lastname" formControlName="lastname" required />
                  </div>

                  <div class="col-sm-12 col-md-6">
                    <input type="text" class="form-control" id="floatingOwnerphonenumber" placeholder="Phone number" formControlName="phonenumber" required />
                  </div>

                  <!-- Address -->
                  <div class="col-sm-12 col-md-12">
                    <autocomplete-addresss (setAddress)="getAddress($event, 'owner')" adressType="geocode"></autocomplete-addresss>
                  </div>

                  <div class="col-sm-12 col-md-12">
                    <input type="text" class="form-control" placeholder="Address2" formControlName="address2"/>
                  </div>

                  <div class="col-sm-12 col-md-6">
                    <input type="text" class="form-control" placeholder="City" formControlName="city" required>
                  </div>

                  <div class="col-sm-12 col-md-6">
                    <input type="text" class="form-control" placeholder="State" formControlName="state" required>
                  </div>

                  <div class="col-sm-12 col-md-6">
                    <input type="text" class="form-control" placeholder="Zip" formControlName="zip" required>
                  </div>

                  <div class="col-sm-12 col-md-6">
                    <input type="text" class="form-control" placeholder="Country" formControlName="country" required>
                  </div>
                  <!-- End Address -->

                  <div class="modal-footer">
                    <button type="button" class="btn btn-light" data-bs-dismiss="modal">Close</button>
                    <button type="submit" class="btn btn-primary">Save & select</button>
                  </div>
                </form>
              </div>

              <div class="col-12" *ngIf="actions.modalForm == 'rider'">
                <form class="row row-cols-lg-12 g-3 align-items-center needs-validation" [formGroup]="riderForm" autocomplete="off" (ngSubmit)="saveSuggestions($event)">
                  <div class="col-sm-12 col-md-6">
                    <div class="input-group flex-nowrap">
                      <span class="input-group-text" *ngIf="loadingNrha">
                        <div class="spinner-border spinner-border-sm" role="status"></div>
                      </span>
                      <input (change)="getPersonByNrha($any($event).target.value, 'rider')" class="form-control" id="floatingNrhaRider" placeholder="NRHA #" formControlName="nrha" required="" />
                    </div>
                  </div>
                  <div class="col-sm-12 col-md-6">
                    <input class="form-control" id="floatingRiderfirsname" placeholder="Firstname" formControlName="firstname" required />
                  </div>
                  <div class="col-sm-12 col-md-6">
                    <input class="form-control" id="floatingRiderlastname" placeholder="Lastname" formControlName="lastname" required />
                  </div>
                  <div class="col-sm-12 col-md-6">
                    <input class="form-control" id="floatingRiderphonenumber" placeholder="Phone number" formControlName="phonenumber" required />
                  </div>

                  <!-- Address -->
                  <div class="col-sm-12 col-md-12">
                    <autocomplete-addresss (setAddress)="getAddress($event, 'rider')"
                      adressType="geocode"></autocomplete-addresss>
                  </div>

                  <div class="col-sm-12 col-md-12">
                    <input type="text" class="form-control" placeholder="Address2" formControlName="address2" />
                  </div>

                  <div class="col-sm-12 col-md-6">
                    <input type="text" class="form-control" placeholder="City" formControlName="city" required="" />
                  </div>

                  <div class="col-sm-12 col-md-6">
                    <input type="text" class="form-control" placeholder="State" formControlName="state" required="" />
                  </div>

                  <div class="col-sm-12 col-md-6">
                    <input type="text" class="form-control" placeholder="Zip" formControlName="zip" required="" />
                  </div>

                  <div class="col-sm-12 col-md-6">
                    <input type="text" class="form-control" placeholder="Country" formControlName="country" required="" />
                  </div>
                  <!-- End Address -->

                  <div class="modal-footer">
                    <button type="button" class="btn btn-light" data-bs-dismiss="modal">Close</button>
                    <button type="submit" class="btn btn-primary">Save & select</button>
                  </div>
                </form>
              </div>

              <div class="col-12" *ngIf="actions.modalForm == 'payee'">
                <form class="row row-cols-lg-12 g-3 align-items-center needs-validation" [formGroup]="payeeForm"
                  autocomplete="off" (ngSubmit)="saveSuggestions($event)">
                  <div class="col-sm-12 col-md-6">
                    <div class="input-group flex-nowrap">
                      <span class="input-group-text" *ngIf="loadingNrha">
                        <div class="spinner-border spinner-border-sm" role="status"></div>
                      </span>
                      <input (change)="getPersonByNrha($any($event).target.value, 'payee')" type="text" class="form-control" id="floatingNrhaPayee" placeholder="NRHA #" formControlName="nrha" required />
                    </div>
                  </div>
                  <div class="col-sm-12 col-md-6">
                    <input type="text" class="form-control" id="floatingPayeefirsname" placeholder="Firstname" formControlName="firstname" required />
                  </div>
                  <div class="col-sm-12 col-md-6">
                    <input type="text" class="form-control" id="floatingPayeelastname" placeholder="Lastname" formControlName="lastname" required />
                  </div>

                  <div class="col-sm-12 col-md-6">
                    <input type="text" class="form-control" id="floatingPayeephonenumber" placeholder="Phone number" formControlName="phonenumber" required />
                  </div>

                  <!-- Address -->
                  <div class="col-sm-12 col-md-12">
                    <autocomplete-addresss (setAddress)="getAddress($event, 'payee')"
                      adressType="geocode"></autocomplete-addresss>
                  </div>

                  <div class="col-sm-12 col-md-12">
                    <input type="text" class="form-control" placeholder="Address2" formControlName="address2" />
                  </div>

                  <div class="col-sm-12 col-md-6">
                    <input type="text" class="form-control" placeholder="City" formControlName="city" required="" />
                  </div>

                  <div class="col-sm-12 col-md-6">
                    <input type="text" class="form-control" placeholder="State" formControlName="state" required="" />
                  </div>

                  <div class="col-sm-12 col-md-6">
                    <input type="text" class="form-control" placeholder="Zip" formControlName="zip" required="" />
                  </div>

                  <div class="col-sm-12 col-md-6">
                    <input type="text" class="form-control" placeholder="Country" formControlName="country" required="" />
                  </div>
                  <!-- End Address -->

                  <div class="modal-footer">
                    <button type="button" class="btn btn-light" data-bs-dismiss="modal">Close</button>
                    <button type="submit" class="btn btn-primary">Save & select</button>
                  </div>
                </form>
              </div>
            </div>


          </div><!-- /.modal-content -->
        </div><!-- /.modal-dialog -->
      </div><!-- /.modal -->

      <div>
        <div class="mt-3">
          <div class="row">

            <div class="col-md-7 col-sm-12">
              <div class="card card-body">
                <div class="col-12">
                  <form class="form-horizontal needs-validation">
                    <h5>ENTRY DATA</h5>
                    <div class="row mb-2">
                      <div class="col-sm-12 col-md-2">
                        <label for="trainer-dropdown" class="col-form-label col-form-label-sm">Trainer <span class="text-danger">*</span></label>
                      </div>
                      <div class="col-sm-12 col-md-8">
                        <div class="input-group">
                          <select id="trainer-dropdown" class="form-control form-control-sm" [(ngModel)]="selects.trainer" [ngModelOptions]="{standalone: true}" required="">
                            <option selected value="">Select a trainer</option>
                            <option *ngFor="let trainer of suggestions.trainer" value="{{ trainer.uid }}">{{ trainer.firstname }} {{ trainer.lastname }} ({{ trainer.nrha }})</option>
                          </select>

                          <button class="btn btn-secondary btn-sm" type="button" data-bs-toggle="popover" title="Trainers" id="popover-trainers">
                            <i class="mdi mdi-pencil"></i>
                          </button>
                        </div>
                      </div>
                      <div class="col-sm-12 col-md-2">
                        <div class="d-grid">
                          <button class="btn btn-success btn-sm" type="button" (click)="newEntity('trainer')">new +</button>
                        </div>
                      </div>
                    </div>

                    <div class="row mb-2">
                      <div class="col-sm-12 col-md-2">
                        <label for="horse-dropdown" class="col-form-label col-form-label-sm">Horse <span
                            class="text-danger">*</span></label>
                      </div>
                      <div class="col-sm-12 col-md-8">
                        <div class="input-group">
                          <select id="horse-dropdown" class="form-control form-control-sm" [(ngModel)]="selects.horse" [ngModelOptions]="{standalone: true}" required>
                            <option selected value="">Select a horse</option>
                            <option *ngFor="let horse of suggestions.horse" value="{{ horse.uid }}">{{ horse.name }} ({{ horse.nrha }})</option>
                          </select>
                          <button class="btn btn-secondary btn-sm" type="button" data-bs-toggle="popover" title="Horses" id="popover-horses">
                            <i class="mdi mdi-pencil"></i>
                          </button>
                        </div>
                      </div>
                      <div class="col-sm-12 col-md-2">
                        <div class="d-grid">
                          <button class="btn btn-success btn-sm" type="button" (click)="newEntity('horse')">new +</button>
                        </div>
                      </div>
                    </div>

                    <div class="row mb-2">
                      <div class="col-sm-12 col-md-2">
                        <label for="rider-dropdown" class=" col-form-label col-form-label-sm">Rider(s) <span
                            class="text-danger">*</span></label>
                      </div>
                      <div class="col-sm-12 col-md-8">
                        <div class="input-group">
                          <select id="rider-dropdown" class="form-control form-control-sm" [(ngModel)]="selects.rider" [ngModelOptions]="{standalone: true}" (change)="addRider($any($event).target.value)">
                            <option value="">Select a rider</option>
                            <option *ngFor="let rider of suggestions.rider" value="{{ rider.uid }}">{{ rider.firstname }}
                              {{ rider.lastname }} ({{ rider.nrha }})</option>
                          </select>
                          <button class="btn btn-secondary btn-sm" type="button" data-bs-toggle="popover" title="Riders" id="popover-riders">
                            <i class="mdi mdi-pencil"></i>
                          </button>
                        </div>

                      </div>
                      <div class="col-sm-12 col-md-2">
                        <div class="d-grid">
                          <button class="btn btn-success btn-sm" type="button" (click)="newEntity('rider')">new
                            +</button>
                        </div>
                      </div>
                    </div>

                    <div class="row justify-content-center" *ngIf="selects.riders.length">
                      <div class="mb-2 col-sm-12 col-md-8">
                        <ul class="list-group">
                          <li class="list-group-item py-1 px-2" *ngFor="let r of selects.riders">{{ r.firstname }} {{ r.lastname }} <span *ngIf="r.nrha">({{ r.nrha }})</span></li>
                        </ul>
                      </div>
                    </div>

                    <div class="row mb-2">
                      <div class="col-sm-12 col-md-2">
                        <label for="owner-dropdown" class="col-form-label col-form-label-sm">Owner <span
                            class="text-danger">*</span></label>
                      </div>
                      <div class="col-sm-12 col-md-8">
                        <div class="input-group">
                          <select id="owner-dropdown" class="form-control form-control-sm" [(ngModel)]="selects.owner" [ngModelOptions]="{standalone: true}" required>
                            <option selected value="">Select an Owner</option>
                            <option *ngFor="let owner of suggestions.owner" value="{{ owner.uid }}">{{ owner.firstname }}
                              {{ owner.lastname }} ({{ owner.nrha }})</option>
                          </select>
                          <button class="btn btn-secondary btn-sm" type="button" data-bs-toggle="popover" title="Owners" id="popover-owners">
                            <i class="mdi mdi-pencil"></i>
                          </button>
                        </div>

                      </div>
                      <div class="col-sm-12 col-md-2">
                        <div class="d-grid">
                          <button class="btn btn-success btn-sm" type="button" (click)="newEntity('owner')">new
                            +</button>
                        </div>
                      </div>
                    </div>


                    <div class="mb-2 col-12 row" *ngIf="actions.showPayeeForm">
                      <label for="payee-dropdown"
                        class="col-sm-12 col-md-2 col-form-label col-form-label-sm">Payee</label>
                      <div class="col-sm-12 col-md-8">
                        <select id="payee-dropdown" class="form-select form-control-sm" [(ngModel)]="selects.payee" [ngModelOptions]="{standalone: true}">
                          <option selected disabled value="">Select a Payee</option>
                          <option *ngFor="let payee of suggestions.payee" value="{{ payee.uid }}">{{ payee.firstname }}
                            {{ payee.lastname }} ({{ payee.nrha }})</option>
                        </select>
                      </div>
                      <button class="col-sm-12 col-md-2 btn btn-success btn-sm" type="button"
                        (click)="newEntity('payee')">new +</button>
                    </div>

                    <div class="mb-2 col-12">
                      <div class="form-check form-checkbox-dark col-sm-12 col-md-8">
                        <input type="checkbox" class="form-check-input" id="customCheckcolor7"
                          (change)="differentPayee($event)">
                        <label class="form-check-label" for="customCheckcolor7">Check if payee different than
                          Owner</label>
                      </div>
                    </div>

                    <div class="mb-2 col-12 row">
                      <label for="rider-dropdown" class="col-sm-12 col-md-2 col-form-label col-form-label-sm">TAX
                        ID</label>
                      <div class="col-md-10 col-sm-12">
                        <div class="col-sm-12 col-md-5">
                          <input type="text" class="form-control form-control-sm" id="tax-id" [(ngModel)]="taxId"
                            [ngModelOptions]="{standalone: true}">
                        </div>
                      </div>
                    </div>

                    <!-- Divisions and classes for non-reining shows -->
                    <div class="mt-3 mb-2 col-12 row text-center" (change)="getDivisionsByType()" *ngIf="!reining">
                      <div class="col-md-3 col-sm-3">
                        <label class="label-radio-button" for="customRadio3" [class.selected]="discipline === 'hunters'">Hunters
                          <input type="radio" id="customRadio3" name="customRadio1" [(ngModel)]="discipline" value="hunters" required>
                        </label>
                      </div>
                      <div class="col-md-3 col-sm-3">
                        <label class="label-radio-button" for="customRadio4" [class.selected]="discipline === 'jumpers'">Nat jumpers
                          <input type="radio" id="customRadio4" name="customRadio1" [(ngModel)]="discipline" value="jumpers" required>
                        </label>
                      </div>
                      <div class="col-md-3 col-sm-3">
                        <label class="label-radio-button" for="customRadio5" [class.selected]="discipline === 'FEI_jumpers'">FEI Jumpers
                          <input type="radio" id="customRadio5" name="customRadio1" [(ngModel)]="discipline" value="FEI_jumpers" required>
                        </label>
                      </div>
                      <div class="col-md-3 col-sm-3">
                        <label class="label-radio-button" for="customRadio6" [class.selected]="discipline === 'Non_showing'">Non showing
                          <input type="radio" id="customRadio6" name="customRadio1" [(ngModel)]="discipline" value="Non_showing" required>
                        </label>
                      </div>
                    </div>

                    <!-- Divisions and classes for reining shows -->
                    <!--div class="mt-3 mb-2 col-12 row text-center" *ngIf="reining">
                      <div class="col-md-3 col-sm-3">
                        <label class="label-radio-button" for="customRadio3" [class.selected]="discipline === 'all'">Add Classes
                          <input type="radio" id="customRadio3" name="customRadio1" [(ngModel)]="discipline" value="all" required>
                        </label>
                      </div>

                    </div-->
                    <div class="card">
                      <h5 class="card-header text-bg-secondary m-0 py-1 px-2">Add Classes</h5>
                      <div class="card-body py-1 px-2">
                        <div class="row">
                          <div class="col-6" *ngFor="let division of divisions">
                            <ul class="list-group">
                              <div class="text-bg-secondary card-header py-1 px-2 mb-2 d-flex justify-content-between">
                                <span>{{ division.name }}</span>
                                <span *ngIf="division.cost > 0">{{ division.cost | currency }}</span>
                              </div>
                              <li *ngFor="let class of division.pruebas" class="d-flex justify-content-between" style="position: relative;display: block;color: black;font-size: small;">
                                <span>
                                  <input class="form-check-input me-1" [attr.ipc]="class.ipc" [attr.division]="division.id" type="checkbox" [value]="class.ipc" aria-label="..." (change)="addClassToEntry(division, class, $any($event).target.checked)">
                                  <strong>{{ class.numero }}</strong>
                                  {{ class.nombre }}
                                </span>
                                <span *ngIf="class.costo > 0">
                                  {{ class.costo | currency }}
                                </span>
                              </li>
                            </ul>
                          </div>
                        </div>
                      </div> <!-- end card-body-->
                    </div>

                    <!--div class="accordion" id="accordionExample" *ngIf="divisions && divisions.length > 0">
                      <div class="accordion-item">
                        <h2 class="accordion-header" id="headingOne">
                          <button class="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseOne" aria-expanded="true" aria-controls="collapseOne">
                            Add classes {{ showInfo?.id_club === 562 ? '(required)' : '(optional)' }}
                          </button>
                        </h2>
                        <div id="collapseOne" class="accordion-collapse collapse" aria-labelledby="headingOne" data-bs-parent="#accordionExample">
                          <div class="accordion-body row">

                          </div>
                        </div>
                      </div>
                    </div-->
                  </form>
                </div>
              </div>
            </div> <!-- end card-->

            <div class="col-md-5 col-sm-12">
              <div class="card card-body">
                <div class="col-12 row">
                  <div class="col-sm-12 col-md-12">
                    <h5>EMERGENCY CONTACT <span class="text-danger">*</span></h5>
                    <form class="row row-cols-lg-12 g-3 align-items-center needs-validation" [formGroup]="emergencyForm" autocomplete="off">
                      <div class="col-sm-12 col-md-6">
                        <input type="text" class="form-control form-control-sm" id="floatingEmergencyName"
                          placeholder="Name" formControlName="fullname" required />
                      </div>
                      <div class="col-sm-12 col-md-6">
                        <input type="text" class="form-control form-control-sm" id="floatingEmergencyPhone"
                          placeholder="Phone number" formControlName="phone" required />
                      </div>
                    </form>
                  </div>

                  <div class="col-sm-12 col-md-12">
                    <h5>STABLING INFO</h5>
                    <form class="row row-cols-lg-12 g-3 align-items-center needs-validation" [formGroup]="stablingForm" autocomplete="off">
                      <div class="col-sm-12 col-md-12">
                        <input type="text" class="form-control form-control-sm" id="floatingStabling" placeholder="Stable with" formControlName="with" />
                      </div>
                    </form>
                  </div>
                  <div class="col-sm-12 col-md-12">
                    <div>
                      <!-- Cargos -->
                      <h4>Stabling, Feed & Fees</h4>
                      <div class="row">
                        <div class="col-12">
                          <div class="row">

                            <div class="col-12" *ngFor="let concepto of conceptos">
                              <form class="form-inline mx-auto row" style="width: fit-content;">
                                <div class="form-group col-6">
                                  <input type="text" readonly="" class="form-control-plaintext"
                                    [value]="concepto.nombre" disabled>
                                </div>
                                <div class="form-group col-3 concepto-input">
                                  <input (change)="agregarCargo(concepto.id, concepto.monto, concepto.nombre, $event)" type="number" min="0" max="100" style="width: 2.5rem; padding: 0; text-align: center;" class="form-control" placeholder="Qty">
                                </div>
                                <div class="form-group col-3">
                                  <label style="color: #212529; font-weight: normal;">{{ concepto.monto | currency }}</label>
                                </div>
                              </form>
                            </div>
                            <!--div class="col-12">
                              <h4 class="text-center">{{ entryAmountWithCCFee | currency }} Deposit Due {{ entriesDue | date }}</h4>
                              <p class="mb-0 text-center" style="font-size: x-small;">ONE HORSE PER ENTRY FORM

                                <br>All horses must have membership cards, a valid coggins and health certificate.
                                <br>This amount includes a {{comision_stripe * 100}}% retail adjustment for Credit Card
                                payments.
                              </p>
                            </div-->
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="col-12">
                <div class="card">
                  <h4 class="card-header m-0 py-1 px-2">Summary</h4>
                  <div class="card-body py-1 px-2">
                    <!-- <h5 class="card-title" *ngIf="classCharges.length > 0">Class & Division Fees</h5> -->
                    <!-- Summary -->
                    <ul class="p-0 mb-0">
                      <li class="d-flex justify-content-between" *ngFor="let class of classCharges">
                        <span>• {{ class.name }}</span>
                        <span>
                          {{ class.amount | currency }}
                          <!-- <a href="javascript:;" style="color: black;" (click)="removeCharge('class', class)">
                            <i class="mdi mdi-close ms-1"></i>
                          </a> -->
                        </span>
                      </li>
                    </ul>
                    <!-- <h5 class="card-title">Stabling, Feed & Fees</h5> -->
                    <ul class="p-0">
                      <li class="d-flex justify-content-between" *ngFor="let charge of conceptosSeleccionados">
                        <span>• {{ charge.name }} ({{ charge.quantity }})</span>
                        <span>
                          {{ charge.total | currency }}
                          <!-- <a href="javascript:;" style="color: black;"  (click)="removeCharge('charge', charge)">
                            <i class="mdi mdi-close ms-1"></i>
                          </a> -->
                        </span>
                      </li>
                    </ul>
                    <div class="col-12 text-center">
                      <h5 class="mb-0">TOTAL : {{ totalCargos() | currency }} </h5>
                    </div>
                  </div>
                </div>
              </div>
            </div> <!-- end card-->

            <div class="card">
              <div class="card-body">
                <!-- Formualario responsable -->
                <h4 class="header-title mb-3">Payment Information</h4>
                <!-- <div class="row justify-content-center">
                  <ng-container *ngFor="let rider of this.selects.riders">
                    <div class="col-sm-12 col-md-4">
                      <div class="form-group mb-0 wrapperRider">
                        <div>
                          <signature-pad [id]="rider.uid" class="rider" [options]="signaturePadOptions" (onBeginEvent)="drawStart('rider')" (onEndEvent)="drawComplete('rider')"></signature-pad>
                        </div>
                      </div>
                      <div class="form-group mb-2">
                        <div class="signature-pad--footer float-left">
                          <div class="description">Sign above (Rider) Required<span class="text-danger">*</span></div>
                        </div>
                        <div class="float-right">
                          <button class="btn btn-light mr-3 float-right" type="button" (click)="clearDraw('rider', rider.uid)">Clear</button>
                        </div>
                      </div>
                    </div>
                  </ng-container> 
                </div> -->
                <!-- <div class="row justify-content-center">
                  <div class="col-sm-12 col-md-4">
                    <div class="form-group mb-0" id="wrapperTrainer">
                      <div>
                        <signature-pad class="trainer" [options]="signaturePadOptions" (onBeginEvent)="drawStart('trainer')" (onEndEvent)="drawComplete('trainer')"></signature-pad>
                      </div>
                    </div>
                    <div class="form-group mb-2">
                      <div class="signature-pad--footer float-left">
                        <div class="description">Sign above (Trainer) Required<span class="text-danger">*</span></div>
                      </div>
                      <div class=" float-right">
                        <button class="btn btn-light mr-3 float-right" type="button" (click)="clearDraw('trainer')">Clear</button>
                      </div>
                    </div>
                  </div>
                  <div class="col-sm-12 col-md-4">
                    <div class="form-group mb-0" id="wrapperOwner">
                      <div>
                        <signature-pad class="owner" [options]="signaturePadOptions" (onBeginEvent)="drawStart('owner')" (onEndEvent)="drawComplete('owner')"></signature-pad>
                      </div>
                    </div>
                    <div class="form-group mb-2 save-top-area">
                      <div class="signature-pad--footer float-left">
                        <div class="description">Sign above (Owner) Required<span class="text-danger">*</span></div>
                      </div>
                      <div class=" float-right">
                        <button class="btn btn-light mr-3" type="button" (click)="clearDraw('owner')">Clear</button>
                      </div>
                    </div>
                  </div>
                </div> -->



                <form class="needs-validation text-center">
                  <div class="form-group mb-3">
                    <div class="custom-control custom-checkbox">
                      <input type="checkbox" class="custom-control-input" name="termsAndConditions"
                        id="termsAndConditions" [(ngModel)]="termsAndConditions"
                        (ngModelChange)="TermsAndConditionsAggrement()" required>
                      <label class="custom-control-label" for="termsAndConditions"> I accept the <a class="text-dark"
                          (click)="showModal()">Terms and Conditions</a><span class="text-danger">*</span>
                      </label>
                    </div>
                  </div>
                </form>

                <form class="needs-validation">
                  <div class="col-12 text-center">
                    <button type="button" class="btn btn-outline-primary mr-1 mb-3" (click)="save()"> Save
                      Entry</button>
                  </div>
                </form>
                <!--p class="text-center">ALL CHECKS IN U.S. FUNDS. PLEASE MAKE CHECKS OUT TO SPLIT ROCK JUMPING TOUR.</p>
                  <p class="text-center">Please mail checks to 5425 Greenwich Pike Lexington, KY, 40511. Attn. Kaitlin Farmer</p-->
                <p class="text-center">In the event of the show being cancelled, all entries will be fully refunded.</p>
              </div>
            </div>

          </div> <!-- end col-->
        </div>
      </div>
    </div>
  </div>
</div>
