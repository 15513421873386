// ref: https://dictionary.cambridge.org/es/gramatica/gramatica-britanica/person-persons-or-people
// ref: https://www.collinsdictionary.com/es/diccionario/ingles/persons

import { Injectable } from '@angular/core';
import { HttpClient, HttpParams, HttpHeaders } from "@angular/common/http";
import { collection, doc, getDocs, query, where, setDoc, deleteDoc } from '@angular/fire/firestore';
import { Observable, firstValueFrom } from 'rxjs';
import { Firestore, addDoc } from '@angular/fire/firestore';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class PersonsService {
  constructor(
    private http: HttpClient,
    private _firestore: Firestore
  ) {
  }


  public getByEmail(email: string, idConcurso: string): Promise<any> {
    const headers: HttpHeaders = new HttpHeaders({
      'x-api-key': environment.api.ed.key,
      'Content-Type': 'application/json'
    });
    const params: any = JSON.stringify({ email, idConcurso });
    return firstValueFrom(this.http.post(`${environment.api.ed.host}entrenadores/byEmail/`, params, { headers: headers })).catch(reason => ({ error: true, message: (reason.error||{}).text||(((reason.error||{}).error||{}).message||(reason.message||(reason.error||(reason||'Error during request.')))) }));
  }

  async getDocumentByEmail(email: string, uid: string = ''): Promise<any> {
    return new Promise(async (resolve, reject) => {
      const usersReference = collection(this._firestore, 'users');
      const q = query(usersReference, where("email", "==", email));
      const querySnapshot = await getDocs(q);
      let document = null;
      if (querySnapshot.empty) {
        const id = uid || email;
        setDoc(doc(this._firestore, `users`, id), { email });
        document = { email, uid: id };
      } else {
        const [firstDoc] = querySnapshot.docs.map(d => ({ ...d.data(), uid: d.id }));
        document = firstDoc;
      }

      return document ? resolve(document) : reject({ error: { code: 'not_found', message: 'User document not found' } });
    });
  }

  public getUsefData(usef: any) {
    const params = JSON.stringify({ usef });
    const headers = new HttpHeaders({
      'x-api-key': environment.api.ed.key,
      'content-Type': 'application/json'
    });
    return this.http.post(`${environment.api.ed.host}personas/usef/`, params, { headers: headers });
  }

  public getECData(ec: any) {
    const headers = new HttpHeaders({
      'x-api-key': environment.api.ed.key,
      'content-Type': 'application/json'
    });
    return this.http.get(`${environment.api.nest.host}ec/RiderBySLN/${ec}`, { headers: headers });
  }

  public getByNrha(nrha: any) {
    const params = { id: nrha };
    const headers = new HttpHeaders({
      'x-api-key': environment.api.ed.key,
      'content-Type': 'application/json'
    });
    return this.http.get(`${environment.api.ed.host}nrha/person/`, { headers, params });
  }

  public getPersonByFEI(fei: string) {
    const params = JSON.stringify({ fei });
    const headers: HttpHeaders = new HttpHeaders({
      'x-api-key': environment.api.ed.key,
      'content-Type': 'application/json'
    });

    return this.http.post(`${environment.api.ed.host}personas/fei/`, params, { headers: headers });
  }

  public async setPersonsSuggestion(userId: any, type: string, data: any): Promise<any> {
    return new Promise(async (resolve, reject) => {
      try {
        const suggestionReference = collection(this._firestore, `users/${userId}/${type}`)
        const docRef = await addDoc(suggestionReference, data);
        return resolve({ documentId: docRef.id });
      } catch (error) {
        return reject({ reason: error });
      }
    });
  }

  async getPersonsSuggestions(user: any, type: any): Promise<any> {
    let documents: any = [];
    const usersReference = collection(this._firestore, `users/${user}/${type}Suggestions`);
    const q = query(usersReference);
    const querySnapshot: any = await getDocs(q).catch(error => ({ error }));
    if(querySnapshot.error) return querySnapshot;
    querySnapshot.forEach((doc:any) => {
      let document: any = doc.data();
      document.uid = doc.id;
      documents.push(document);
    });
    return documents;
  }

  public getTrainerList(idConcurso: string, email: string): Observable<any> {
    const headers: HttpHeaders = new HttpHeaders({
      'x-api-key': environment.api.ed.key,
      'Content-Type': 'application/json'
    });
    const params: any = JSON.stringify({ idConcurso, email });
    return this.http.post(`${environment.api.ed.host}listas/listas-entrenador/by-email/`, params, { headers: headers });
  }

  async deletePersonSuggestion(user:any, documentId:any, type: any):Promise<any>{
    const document = doc(this._firestore, `users/${user}/${type}Suggestions/${documentId}`);
    deleteDoc(document);
  }

  public async updatePersonSuggestion(userId: string, personId: string, role: string, person: any): Promise<any> {
    try {
      const docRef = doc(this._firestore, `users/${userId}/${role}Suggestions/${personId}`);
      await setDoc(docRef, person, { merge: true });
      return { success: true };
    } catch (error) {
      console.error('Error updating person:', error);
      throw error;
    }
  }
}
