<div class="position-absolute start-0 end-0 start-0 bottom-0 w-100 h-100">
  <svg xmlns="http://www.w3.org/2000/svg" width="100%" height="100%" viewBox="0 0 800 800">
      <g fill-opacity="0.22">
          <circle style="fill: rgba(var(--ct-primary-rgb), 0.1);" cx="400" cy="400" r="600"></circle>
          <circle style="fill: rgba(var(--ct-primary-rgb), 0.2);" cx="400" cy="400" r="500"></circle>
          <circle style="fill: rgba(var(--ct-primary-rgb), 0.3);" cx="400" cy="400" r="300"></circle>
          <circle style="fill: rgba(var(--ct-primary-rgb), 0.4);" cx="400" cy="400" r="200"></circle>
          <circle style="fill: rgba(var(--ct-primary-rgb), 0.5);" cx="400" cy="400" r="100"></circle>
      </g>
  </svg>
</div>
<div class="wrapper">
  <div class="">
    <div class="content">
        <!-- Start Content-->
        <div class="container-fluid">
            <div class="row justify-content-center">
                <div class="col-xxl-10">
                    <!-- Enhanced Header Section -->
                    <div class="page-header text-center mb-4">
                      <h2 class="display-6 fw-bold mb-3">Shows</h2>
                      <div class="row justify-content-center">
                        <div class="col-md-8 col-lg-6">
                          <p class="text-muted lead" id="statusMessage">
                            Please select a show to continue
                          </p>
                        </div>
                      </div>
                    </div>

                    <!-- Plans -->
                    <div class="row mt-sm-2 mb-3" *ngIf="shows.length > 0">
                      <div class="col-xl-4 col-lg-4 col-md-6 col-sm-12 col-12" *ngFor="let show of shows">
                        <div class="card d-block concurso" (click)="selectShow(show.idConcurso, show.nombre, show.id_club)">
                          <img class="card-img-top" [src]="show.imagen" alt="" onerror="this.style.display = 'none'" onload="this.style.display = 'unset'">
                          <div class="card-body">
                            <h5 class="card-title">{{ show.nombre }}</h5>
                            <p class="card-text mb-1"><i class="far fa-calendar-alt me-2"></i>{{ show?.inicio | date }} - {{ show?.fin | date }}</p>
                            <p class="card-text"><i class="fas fa-map-marker-alt me-2"></i>{{ show.lugar }}</p>
                          </div> <!-- end card-body-->
                        </div>
                      </div>

                      <ng-container *ngIf="!production && isAdmin">
                        <div class="col-xl-4 col-lg-4 col-md-6 col-sm-12 col-12">
                          <div class="card d-block concurso" (click)="selectShow('546', 'Silver Series Central 1','565')">
                            <img class="card-img-top" [src]="'https://firebasestorage.googleapis.com/v0/b/ecuestredigital/o/portada%2F546?alt=media&token=ec953c4e-c549-41fc-a99b-ffecefa38e8b'" alt="" onerror="this.style.display = 'none'" onload="this.style.display = 'unset'">
                            <div class="card-body">
                              <h5 class="card-title">Silver Series Central 1</h5>
                              <p class="card-text mb-1">April 30 - 03, 2025</p>
                              <p class="card-text">Demo</p>
                            </div> <!-- end card-body-->
                          </div>
                        </div>
                        <div class="col-xl-4 col-lg-4 col-md-6 col-sm-12 col-12"  *ngIf="is_included(email, 'alex@ecuestre.digital', 'mauricio@vende.io','alan@vende.io')">
                          <div class="card d-block concurso" (click)="selectShow('490', '2024 Test Show','559')">
                            <img class="card-img-top" [src]="'https://firebasestorage.googleapis.com/v0/b/ecuestredigital/o/portada%2F485?alt=media&token=99b61c05-e2fd-4df0-9daf-b4bc78129518'" alt="" onerror="this.style.display = 'none'" onload="this.style.display = 'unset'">
                            <div class="card-body">
                              <h5 class="card-title">2024 Test Show (TC)</h5>
                              <p class="card-text mb-1">Abr 12, 2022 - Abr 30, 2023</p>
                              <p class="card-text">Demo</p>
                            </div> <!-- end card-body-->
                          </div>
                        </div>
                        <div class="col-xl-4 col-lg-4 col-md-6 col-sm-12 col-12"  *ngIf="is_included(email, 'alex@ecuestre.digital', 'mauricio@vende.io','alan@vende.io')">
                          <div class="card d-block concurso" (click)="selectShow('402', 'Test Show (internal)','377')">
                            <img class="card-img-top" [src]="'https://firebasestorage.googleapis.com/v0/b/ecuestredigital/o/portada%2F329?alt=media&token=d902a273-11d0-4867-bc91-c7391c1849bc'" alt="" onerror="this.style.display = 'none'" onload="this.style.display = 'unset'">
                            <div class="card-body">
                              <h5 class="card-title">Test Show (internal)</h5>
                              <p class="card-text mb-1">Abr 12, 2022 - Abr 30, 2023</p>
                              <p class="card-text">Demo</p>
                            </div> <!-- end card-body-->
                          </div>
                        </div>
                        <div class="col-xl-4 col-lg-4 col-md-6 col-sm-12 col-12">
                          <div class="card d-block concurso" (click)="selectShow('507', '100X Demo Show','563')">
                            <img class="card-img-top" [src]="'https://firebasestorage.googleapis.com/v0/b/ecuestredigital/o/portada%2F492?alt=media&token=df16d4e0-b3f4-4f66-8375-9d48e265c7d1'" alt="" onerror="this.style.display = 'none'" onload="this.style.display = 'unset'">
                            <div class="card-body">
                              <h5 class="card-title">100X Demo Show</h5>
                              <p class="card-text mb-1">May 15 - 21, 2024</p>
                              <p class="card-text">Demo</p>
                            </div> <!-- end card-body-->
                          </div>
                        </div>
                      </ng-container>
                    </div>
                    <!-- end row -->
                </div> <!-- end col-->
            </div>
            <!-- end row -->

        </div> <!-- container -->

    </div> <!-- content -->
</div>

  <!-- end page -->
  <footer class="footer footer-alt d-none d-sm-block">
    {{ currentYear }} © Equestrian Digital - equestrian.digital
  </footer>
</div>
