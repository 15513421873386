<div class="wrapper">
  <app-top-menu></app-top-menu>
  <app-left-menu></app-left-menu>
  <div class="content-page">
    <div class="container-fluid">
      <!-- Checkout Steps Header -->
      <div class="row mt-2">
        <div class="col-12">
          <div class="checkout-steps">
            <div class="step" [ngClass]="{'active': actions.moveObject}">
              <div class="step-number">1</div>
              <div class="step-title">Select Entries</div>
            </div>
            <div class="step-connector"></div>
            <div class="step" [ngClass]="{'active': actions.betterpayFormVisible}">
              <div class="step-number">2</div>
              <div class="step-title">Payment Details</div>
            </div>
            <div class="step-connector"></div>
            <div class="step">
              <div class="step-number">3</div>
              <div class="step-title">Confirmation</div>
            </div>
          </div>
        </div>
      </div>

      <!-- Entry Selection Section -->
      <div class="row mt-0" *ngIf="actions.moveObject">
        <div class="col-12">
          <div class="card">
            <div class="card-header bg-light">
              <h4 class="mb-0">
                <span>Entries for show: <strong>{{ show.fullname || 'Loading...' }}</strong></span>
                <small class="ms-2">(Need to switch to another show? <a href="/select-show" class="text-primary">Click here</a>)</small>
              </h4>
            </div>
            <div class="card-body">
              <div class="row">
                <!-- Available Entries -->
                <div class="col-md-6 col-12">
                  <h5 class="section-title">Saved Entries</h5>
                  <div class="entries-table-container">
                    <table class="table table-hover entries-table">
                      <thead>
                        <tr>
                          <th>Horse</th>
                          <th class="people-column">People</th>
                          <th class="text-end">Actions</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr *ngFor="let d of drafts">
                          <td class="horse-cell">
                            <div>
                              {{ d.horseName }}
                              <div class="horse-details">
                                <span class="badge ms-1" [ngClass]="{'bg-info': d.discipline == 'jumpers','bg-success': d.discipline == 'hunters','bg-primary': d.discipline == 'FEI_jumpers'}">{{ d.discipline }}</span>
                                <!-- Mobile-only people count indicator -->
                                <span class="badge bg-secondary ms-1 mobile-people-count" *ngIf="d?.riders && d.riders.length > 0">
                                  <i class="mdi mdi-account-group me-1"></i>{{ d.riders.length + 2 }}
                                </span>
                              </div>
                            </div>
                          </td>
                          <td class="people-cell">
                            <div class="entry-people-compact">
                              <div><i class="mdi mdi-account text-muted"></i> <span class="role-label">Owner:</span> {{ d.ownername }} {{ d.ownerLastname }}</div>
                              <div><i class="mdi mdi-account-hard-hat text-muted"></i> <span class="role-label">Trainer:</span> {{ d.trainername }} {{ d.trainerLastname }}</div>
                              <div *ngIf="d?.riders && d.riders.length > 0" class="riders-row">
                                <i class="mdi mdi-horse-human text-muted"></i> <span class="role-label">Rider{{ d.riders.length > 1 ? 's' : '' }}:</span>
                                <span *ngFor="let rider of d?.riders; let i = index">
                                  {{ rider.firstname | titlecase }} {{ rider.lastname | titlecase }}{{ i < d.riders.length - 1 ? ', ' : '' }}
                                </span>
                              </div>
                            </div>
                          </td>
                          <td class="text-end action-cell">
                            <button class="btn btn-sm btn-outline-danger me-1" (click)="deleteDraft(d.uid)">
                              <i class="ri-delete-bin-2-line"></i>
                            </button>
                            <button class="btn btn-sm btn-primary" (click)="moveObject(d,drafts,paying)">
                              <i class="ri-arrow-right-line"></i>
                            </button>
                          </td>
                        </tr>
                        <tr *ngIf="drafts.length === 0">
                          <td colspan="3" class="text-center py-3">
                            <i class="mdi mdi-information-outline text-muted d-block mb-2"></i>
                            <p class="mb-0">No entries available for payment</p>
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </div>

                <!-- Selected Entries (Cart) -->
                <div class="col-md-6 col-12">
                  <h5 class="section-title">Your Cart</h5>
                  <div class="entries-table-container">
                    <table class="table table-hover entries-table">
                      <thead>
                        <tr>
                          <th>Horse</th>
                          <th class="people-column">People</th>
                          <th class="text-end">Actions</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr *ngFor="let d of paying">
                          <td class="horse-cell">
                            <div>
                              {{ d.horseName }}
                              <div class="horse-details">
                                <span class="badge ms-1" [ngClass]="{'bg-info': d.discipline == 'jumpers','bg-success': d.discipline == 'hunters','bg-primary': d.discipline == 'FEI_jumpers'}">{{ d.discipline }}</span>
                                <!-- Mobile-only people count indicator -->
                                <span class="badge bg-secondary ms-1 mobile-people-count" *ngIf="d?.riders && d.riders.length > 0">
                                  <i class="mdi mdi-account-group me-1"></i>{{ d.riders.length + 2 }}
                                </span>
                              </div>
                            </div>
                          </td>
                          <td class="people-cell">
                            <div class="entry-people-compact">
                              <div><i class="mdi mdi-account text-muted"></i> <span class="role-label">Owner:</span> {{ d.ownername }} {{ d.ownerLastname }}</div>
                              <div><i class="mdi mdi-account-hard-hat text-muted"></i> <span class="role-label">Trainer:</span> {{ d.trainername }} {{ d.trainerLastname }}</div>
                              <div *ngIf="d?.riders && d.riders.length > 0" class="riders-row">
                                <i class="mdi mdi-horse-human text-muted"></i> <span class="role-label">Rider{{ d.riders.length > 1 ? 's' : '' }}:</span>
                                <span *ngFor="let rider of d?.riders; let i = index">
                                  {{ rider.firstname | titlecase }} {{ rider.lastname | titlecase }}{{ i < d.riders.length - 1 ? ', ' : '' }}
                                </span>
                              </div>
                            </div>
                          </td>
                          <td class="text-end action-cell">
                            <button class="btn btn-sm btn-outline-secondary" (click)="moveObject(d,paying,drafts)">
                              <i class="ri-arrow-left-line"></i>
                            </button>
                          </td>
                        </tr>
                        <tr *ngIf="paying.length === 0">
                          <td colspan="3" class="text-center py-3">
                            <i class="mdi mdi-cart-outline text-muted d-block mb-2"></i>
                            <p class="mb-0">Your cart is empty</p>
                            <p class="text-muted small">Add entries from the left to proceed with payment</p>
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>

                  <!-- Cart Summary -->
                  <div class="cart-summary mt-2" *ngIf="paying.length > 0">
                    <div class="d-flex justify-content-between mb-1">
                      <span>Total Entries:</span>
                      <span>{{ paying.length }}</span>
                    </div>
                    <div class="d-flex justify-content-between mb-1">
                      <span>Subtotal:</span>
                      <span>${{ cartTotals.subtotal.toFixed(2) }}</span>
                    </div>
                    <div class="d-flex justify-content-between mb-1 processing-fee-row">
                      <span>
                        <strong>Convenience Fee</strong>
                        <i class="mdi mdi-information-outline ms-1" 
                           data-bs-toggle="tooltip" 
                           data-bs-placement="top" 
                           title="A small fee is charged to cover credit card processing costs"></i>
                        <small class="text-muted" *ngIf="show?.club?.comision_stripe"> ({{ show.club.comision_stripe }}%)</small>
                      </span>
                      <span><strong>${{ cartTotals.processingFee.toFixed(2) }}</strong></span>
                    </div>
                    <hr class="my-2">
                    <div class="d-flex justify-content-between mb-1 total-row">
                      <span class="fw-bold">Total Amount:</span>
                      <span class="fw-bold">${{ cartTotals.total.toFixed(2) }}</span>
                    </div>
                    <div class="d-grid">
                      <button class="btn btn-primary" (click)="selectPaymentMethod()">
                        Proceed to Payment <i class="ri-arrow-right-line ms-1"></i>
                      </button>
                      <small class="text-muted text-center mt-2" *ngIf="cartTotals.processingFee > 0">
                        <i class="mdi mdi-information-outline me-1"></i>
                        A {{ show.club.comision_stripe }}% processing fee is applied to all credit card payments
                      </small>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <!-- Help Information -->
          <div class="card mt-3">
            <div class="card-body">
              <div class="d-flex align-items-center">
                <i class="mdi mdi-information-outline text-info me-3 fs-4"></i>
                <p class="mb-0">Select entries you want to pay for now (you can pay for more than one at a time).</p>
                <p class="mb-0">You will need to checkout separately for each show.</p>
              </div>
            </div>
          </div>
        </div>
      </div>

      <!-- Payment Form Section -->
      <div class="row mt-0" *ngIf="actions.betterpayFormVisible" [@fadeInOut]>
        <div class="col-lg-8 col-md-7 col-12">
          <div class="card">
            <div class="card-header bg-light">
              <h4 class="mb-0">Credit Card Details</h4>
            </div>
            <div class="card-body">
              <form [formGroup]="betterpayForm">
                <div class="row">
                  
                  
                  <!-- Saved Cards Section -->
                  <div class="col-12 mb-0" *ngIf="savedCards.length > 0">
                    <div class="saved-cards-container">
                      <h5 class="mb-0">Payment Method</h5>
                      
                      <!-- Loading state -->
                      <div *ngIf="isLoadingSavedCards" class="text-center py-3">
                        <div class="spinner-border text-primary" role="status">
                          <span class="visually-hidden">Loading...</span>
                        </div>
                        <p class="mt-2 mb-1">Loading saved cards...</p>
                      </div>
                      
                      <!-- Saved cards list -->
                      <div *ngIf="!isLoadingSavedCards" class="saved-cards-list">
                        <p class="text-muted mb-2" *ngIf="savedCards.length > 0">Select a saved card or add a new one:</p>
                        <div *ngFor="let card of savedCards" class="saved-card-item mb-1" 
                             [ngClass]="{'selected': selectedSavedCard?.id === card.id}">
                          <div class="form-check">
                            <input class="form-check-input" type="radio" [id]="'card-' + card.id" 
                                   [checked]="selectedSavedCard?.id === card.id"
                                   (change)="selectSavedCard(card)">
                            <label class="form-check-label" [for]="'card-' + card.id">
                              <div class="d-flex align-items-center">
                                <img [src]="'assets/images/brands/' + card.cc_type + '.png'" class="me-2" style="width: 48px;">
                               
                                <div class="card-info-horizontal">
                                  <span class="card-number me-2"> **** {{ card.cc_number | slice:-4 }}</span>
                                  <span class="card-expiry me-2">Exp: {{ card.cc_exp }}</span>
                                  <span class="card-holder text-muted" *ngIf="card.cardholder">
                                    <i class="mdi mdi-account me-1"></i>{{ card.cardholder }}
                                  </span>
                                  <span class="card-holder text-muted" *ngIf="!card.cardholder && (card.first_name || card.last_name)">
                                    <i class="mdi mdi-account me-1"></i>{{ card.first_name }} {{ card.last_name }}
                                  </span>
                                </div>
                              </div>
                            </label>
                          </div>
                        </div>
                        
                        <!-- Add new card option -->
                        <div class="saved-card-item new-card-option" [ngClass]="{'selected': showNewCardForm}">
                          <div class="form-check">
                            <input class="form-check-input" type="radio" id="new-card" 
                                   [checked]="showNewCardForm"
                                   (change)="showAddNewCard()">
                            <label class="form-check-label" for="new-card">
                              <div class="d-flex align-items-center">
                                <i class="mdi mdi-credit-card-plus me-2"></i>
                                <span>Use a new card</span>
                              </div>
                            </label>
                          </div>
                        </div>
                        
                        <!-- Single "Use this Card" button -->
                        <div class="d-grid mt-3" *ngIf="selectedSavedCard && !showNewCardForm">
                          <button type="button" class="btn btn-primary" 
                                  (click)="useThisCard(selectedSavedCard)">
                            <i class="mdi mdi-credit-card-check me-1"></i> Use this Card
                          </button>
                          <small class="text-muted text-center mt-2">
                            <i class="mdi mdi-information-outline me-1"></i>
                            Click "Use this Card" to confirm your selection and enable payment
                          </small>
                        </div>
                      </div>
                    </div>
                    
                  </div>
                  
                  <!-- New Card Form -->
                  <div *ngIf="showNewCardForm || savedCards.length === 0" class="new-card-form col-12" style="margin: 1%; width: 98%;">
                    <div class="row">
                      <div class="col-md-6 col-12 mb-3">
                        <label for="firstName" class="form-label">First Name</label>
                        <input type="text" class="form-control" id="firstName" formControlName="firstName" placeholder="Enter first name">
                        <div class="invalid-feedback" *ngIf="betterpayForm.get('firstName')?.invalid && betterpayForm.get('firstName')?.touched">
                          First name is required
                        </div>
                      </div>
                      <div class="col-md-6 col-12 mb-3">
                        <label for="lastName" class="form-label">Last Name</label>
                        <input type="text" class="form-control" id="lastName" formControlName="lastName" placeholder="Enter last name">
                        <div class="invalid-feedback" *ngIf="betterpayForm.get('lastName')?.invalid && betterpayForm.get('lastName')?.touched">
                          Last name is required
                        </div>
                      </div>
                    </div>
                    
                    <div class="row">
                      <div class="col-12 mb-3">
                        <label for="cardNumber" class="form-label">Card Number</label>
                        <div class="input-group">
                          <div id="ccnumber" class="form-control"></div>
                          <span class="input-group-text card-brand-icon" *ngIf="cardBrand">
                            <i [class]="getCardBrandIcon()"></i>
                          </span>
                          <span class="input-group-text card-brand-icon" *ngIf="!cardBrand">
                            <i class="fa-regular fa-credit-card"></i>
                          </span>
                        </div>
                        <div class="invalid-feedback" *ngIf="!isCardNumberValid && cardNumberTouched">
                          Please enter a valid card number
                        </div>
                      </div>
                    </div>
                    
                    <div class="row">
                      <div class="col-md-6 col-12 mb-3">
                        <label for="expiryDate" class="form-label">Expiry Date</label>
                        <div id="ccexp" class="form-control"></div>
                        <div class="invalid-feedback" *ngIf="!isExpiryValid && expiryTouched">
                          Please enter a valid expiry date
                        </div>
                      </div>
                      
                      <div class="col-md-6 col-12 mb-3">
                        <label for="cvv" class="form-label">CVV</label>
                        <div id="cccvv" class="form-control"></div>
                        <div class="invalid-feedback" *ngIf="!isCvvValid && cvvTouched">
                          Please enter a valid CVV
                        </div>
                      </div>
                    </div>

                    <div class="row">
                      <div class="col-12 mb-3">
                        <label for="zipCode" class="form-label">Billing Zip Code</label>
                        <input type="text" class="form-control" id="zipCode" formControlName="zipCode" placeholder="Enter billing zip code">
                        <div class="invalid-feedback" *ngIf="betterpayForm.get('zipCode')?.invalid && betterpayForm.get('zipCode')?.touched">
                          Billing zip code is required
                        </div>
                      </div>
                    </div>
                    
                    <!-- Add Validate Card Button (only for new card) -->
                    <div class="d-grid mt-3">
                      <button type="button" class="btn btn-success" id="validate-card-button" *ngIf="!paymentToken"
                              [disabled]="!isCardNumberValid || !isExpiryValid || !isCvvValid || isValidating || paymentToken || 
                                         !betterpayForm.get('firstName')?.valid || !betterpayForm.get('lastName')?.valid || !betterpayForm.get('zipCode')?.valid" 
                              (click)="validateCard()">
                        <span *ngIf="isValidating" class="spinner-border spinner-border-sm me-1" role="status" aria-hidden="true"></span>
                            {{ isValidating ? 'Validating...' : 'Validate Card' }}
                      </button>
                    </div>
                  </div>
                  
                  
                </div>
                
                <div class="payment-security-info mt-0">
                  <div class="alert alert-info d-flex align-items-center">
                    <div class="security-icon me-3">
                      <i class="mdi mdi-shield-lock-outline fs-3"></i>
                    </div>
                    <div class="security-text">
                      <h6 class="mb-1">Secure Payment Processing</h6>
                      <p class="mb-0">Your payment information is securely processed. We do not store your credit card details.</p>
                    </div>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
        
        <!-- Order Summary -->
        <div class="col-lg-4 col-md-5 col-12">
          <div class="card">
            <div class="card-header bg-light">
              <h4 class="mb-0">Order Summary</h4>
            </div>
            <div class="card-body">
              <div class="order-summary">
                <div class="entries-summary">
                  <h6 class="mb-3">Entries ({{ paying.length }})</h6>
                  <div class="entries-table">
                    <table class="table table-sm">
                      <thead>
                        <tr>
                          <th>Horse</th>
                          <th class="text-end">Amount</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr *ngFor="let entry of paying">
                          <td>
                            {{ entry.horseName }}
                            <div class="d-flex align-items-center mt-1">
                              <span class="badge me-2" [ngClass]="{'bg-info': entry.discipline == 'jumpers','bg-success': entry.discipline == 'hunters','bg-primary': entry.discipline == 'FEI_jumpers'}">{{ entry.discipline }}</span>
                            </div>
                          </td>
                          <td class="text-end">${{ (entry.balance || (showInfo.entryAmount || '0')) }}</td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </div>
                
                <hr>
                
                <div class="total-section">
                  <div class="d-flex justify-content-between mb-2">
                    <span>Subtotal</span>
                    <span>${{ cartTotals.subtotal.toFixed(2) }}</span>
                  </div>
                  <div class="d-flex justify-content-between mb-2 processing-fee-row">
                    <span>
                      <strong>Convenience Fee</strong>
                      <i class="mdi mdi-information-outline ms-1" 
                         data-bs-toggle="tooltip" 
                         data-bs-placement="top" 
                         title="A small fee is charged to cover credit card processing costs"></i>
                      <small class="text-muted" *ngIf="show?.club?.comision_stripe">({{ show.club.comision_stripe }}%)</small>
                    </span>
                    <span><strong>${{ cartTotals.processingFee.toFixed(2) }}</strong></span>
                  </div>
                  <hr class="my-2">
                  <div class="d-flex justify-content-between total-row">
                    <span class="fw-bold">Total</span>
                    <span class="fw-bold">${{ cartTotals.total.toFixed(2) }}</span>
                  </div>
                </div>
                
                <div class="d-grid gap-2 mt-4">
                  <button type="button" class="btn btn-primary btn-lg" id="complete-payment-button" 
                          [disabled]="(!actions.formIsValid || isProcessing)" 
                          [ngClass]="{'btn-highlight': selectedSavedCard && actions.formIsValid && !isProcessing}"
                          [title]="(!actions.formIsValid && selectedSavedCard) ? 'Please click -Use this Card- to confirm your selection' : ''"
                          (click)="processPayment()">
                    <span *ngIf="isProcessing" class="spinner-border spinner-border-sm me-2" role="status" aria-hidden="true"></span>
                    <i *ngIf="!isProcessing" class="mdi mdi-check-circle-outline me-2"></i>
                    {{ isProcessing ? 'Processing...' : 'Complete Payment' }}
                  </button>
                  
                  <!-- Indicator when button is disabled due to needing card confirmation -->
                  <div *ngIf="selectedSavedCard && !actions.formIsValid && !isProcessing" class="text-center mt-1 card-confirmation-hint">
                    <small class="text-warning">
                      <i class="mdi mdi-alert-circle-outline me-1"></i>
                      Please confirm your card selection by clicking "Use this Card"
                    </small>
                  </div>
                  
                  <button type="button" class="btn btn-outline-secondary" (click)="returnToSelectEntries()">
                    <i class="mdi mdi-arrow-left me-1"></i> Back to Entries
                  </button>
                </div>
              </div>
            </div>
          </div>
          
          <!-- Payment Security -->
          <div class="card mt-3">
            <div class="card-body">
              <div class="payment-security">
                <div class="d-flex align-items-center mb-2">
                  <i class="mdi mdi-shield-check-outline text-success me-2 fs-4"></i>
                  <span class="fw-bold">Secure Payment</span>
                </div>
                <p class="mb-0 small">Your payment information is encrypted and securely processed.</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
