declare var require: any;
export const environment = {
  production: true,
  version: require('../../package.json').version,
  developers: ['alex@ecuestre.digital', 'mauricio@vende.io', 'alan@vende.io'],
  api: {
    ed: {
      host: 'https://api.equestrian.digital/',
      key: '7cc263a1-a490-4337-8302-151490495e56'
    },
    nest: {
      host: 'https://nest.equestrian.digital/v1/'
    },
    nrha: {
      host: 'https://data.nrha.com/api/private/rs/',
      key: 'Y[>KQf)Rs}JU_G>>}DL^WB$OF6X{O$d1$(=<(jbLl{Fq0iIZiOENPkkuZrIiNxAvYJ^{@mfOaX6?7YoA]mw[)2Oy1M3T]+$Ja%!8G7%FbyW'
    },
    reining: {
      host: 'https://reining.ecuestrian.digital'
    }
  },
  betterpay: {
    562:{ // TC
      key: 'k8PHD6-5CjFQh-4m7F2v-bC3t6b'
    },
    565:{ // caledon
      key: '3hgzPS-5wAnfm-zt8F8m-9vVAPH'
    },
    567:{ // angelstone
      key: '742GK6-Q95P96-dRpjH6-98p7p2'
    },
    1:{ // testing
      key: 'TRtnH7-324hGd-2Zb7Tg-5Rrn56'
    },
    377:{ // SR
      key: '7XRY5t-sUH7N6-CzwMUD-5unAnQ'
    }
  }
};
